import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Box,
    Button,
    Typography,
    CircularProgress,
    IconButton,
    LinearProgress,
    Grid,
    Avatar,
    Paper
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getApi, postApi } from "../../services/axiosInstance";
import { API_PATH } from "../../services/apipath";

const Result = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [resultData, setResultData] = useState(null);
    const [studentId, setStudentId] = useState(null);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState({
        name: "SYNTHESISMONICA", image: ""
    });

    const fetchResultData = async () => {
        setLoading(true);
        try {
            const res = await getApi(`${API_PATH.MOCK_TEST.TEST_SCORE}/${id}`);
            if (res.status === 200) {
                setResultData(res.data);
                setStudentId(res.data.studentId);
                setUserData(res.data.studentId)
                setLoading(false)

            } else {
                throw new Error("Failed to fetch test result data");
            }
        } catch (err) {
            setError(err.message);
            console.log("Error fetching test result:", err);
            setResultData(getMockData());
        }finally{
            setLoading(false)
        }
    };
    
    useEffect(() => {

        fetchResultData();
    }, [id]);

    
    // Mock data for development and testing
    const getMockData = () => {
        return {
            userName: "SYNTHESISMONICA",
            userImage: "",
            overallScore: 45,
            communicativeSkills: {
                Listening: 32,
                Reading: 55,
                Speaking: 35,
                Writing: 57
            },
            enablingSkills: {
                Grammar: 45,
                OralFluency: 33,
                Pronunciation: 32,
                Spelling: 45,
                Vocabulary: 37,
                WrittenDiscourse: 90
            }
        };
    };

    const handleBack = () => {
        navigate(-1);
    };

    // Custom circular progress component
    const ScoreCircle = ({ value, label, color }) => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', alignItems: 'center', m: 1 }}>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress
                        variant="determinate"
                        value={100}
                        size={80}
                        thickness={3}
                        sx={{ color: 'rgba(255, 255, 255, 0.1)' }}
                    />
                    <CircularProgress
                        variant="determinate"
                        value={value}
                        size={80}
                        thickness={3}
                        sx={{
                            color: color || '#9c27b0',
                            position: 'absolute',
                            left: 0,
                        }}
                    />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="h6" component="div" color="white">
                            {value}
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                    {label}
                </Typography>
            </Box>
        );
    };

    // Custom progress bar component
    const SkillProgressBar = ({ label, value, color }) => {
        // Define maximum possible score
        const MAX_SCORE = 90;
        
        // Make sure value is treated as a number
        const numericValue = Number(value);
        
        // Calculate percentage (capped at 100)
        const percentage = Math.min(Math.floor((numericValue / MAX_SCORE) * 100), 100);
        
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="body2" sx={{ width: '140px', color: 'rgba(255, 255, 255, 0.7)' }}>
                    {label}
                </Typography>
                <Typography variant="body2" sx={{ width: '30px', textAlign: 'right', mr: 2, color: 'white' }}>
                    {numericValue}
                </Typography>
                <Box sx={{ flexGrow: 1, position: 'relative' }}>
                    <Box sx={{
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        width: '100%',
                        position: 'relative'
                    }}>
                        <Box sx={{
                            position: 'absolute',
                            height: '100%',
                            width: `${percentage}%`,
                            borderRadius: 4,
                            backgroundColor: color,
                            transition: 'width 0.5s ease'
                        }} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        height: '100%',
                        width: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        ml: 2,
                        mr: 2
                    }}
                />
            </Box>
        );
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    backgroundColor: '#181B2A',
                }}
            >
                <CircularProgress color="primary" />
            </Box>
        );
    }

    // Color mapping for different skills
    const skillColors = {
        Listening: '#4D6BFE',
        Reading: '#D6D84F',
        Speaking: '#4CAF50',
        Writing: '#9C27B0',
        Grammar: '#00BCD4',
        OralFluency: '#00BCD4',
        Pronunciation: '#00BCD4',
        Spelling: '#00BCD4',
        Vocabulary: '#00BCD4',
        WrittenDiscourse: '#00BCD4'
    };

    // Use resultData or fallback to mock data
    const data = resultData || getMockData();
    console.log(data);


    return (
        <Box
            sx={{
                backgroundColor: "#181B2A",
                color: "white",
                position: "relative",
                minHeight: "100vh", // Ensure full height
                display: "flex", // Centering
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
            }}
        >
            <Box sx={{
                // height: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            }}>
            </Box>


            <Paper
                elevation={3}
                sx={{
                    backgroundColor: "#232738",
                    // padding: "10px",
                    maxWidth: "600px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    color: "white",
                    width: "100%", // Make it responsive
                    margin: "20px 0",
                }}
            >
                <Box sx={{ padding: "20px" }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Avatar
                                src={userData?.image || ""}
                                alt={userData?.name || "User"}
                                sx={{ width: 50, height: 50, color: "#fff" }}
                            />
                        </Grid>

                        <Grid item xs>
                            <Typography variant="h6" sx={{ textTransform: "uppercase", color:"white !important" }}>
                                {userData.name || "User"}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant="caption" sx={{ color: "#ffffff", fontSize: "18px" }}>
                                    Overall Score
                                </Typography>
                                <Box
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        borderRadius: "50%",
                                        backgroundColor: "#9C27B0",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        margin: "0 auto"
                                    }}
                                >
                                    <Typography variant="h4" sx={{ fontWeight: "bold", color: "white" }}>
                                        {data.overallScore || 0}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ mt: 2, mb: 1, color: "#ffffff", fontSize:"18px" }}>
                        Communicative Skills
                    </Typography>
                    {console.log(data.communicativeSkills)}
                    
                    <Box sx={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap", mb: 1 }}>
                    {
                        data.communicativeSkills?.Listening > 9 && (<ScoreCircle
                            value={data.communicativeSkills?.Listening || 0}
                            label="Listening"
                            color={skillColors.Listening}
                            sx={{ color: "black" }}
                        />)
                    }
                    {
                        data.communicativeSkills?.Reading > 9 && (<ScoreCircle
                            value={data.communicativeSkills?.Reading || 0}
                            label="Reading"
                            color={skillColors.Reading}
                        />)
                    }
                    {
                        data.communicativeSkills?.Speaking > 9 && (<ScoreCircle
                            value={data.communicativeSkills?.Speaking || 0}
                            label="Speaking"
                            color={skillColors.Speaking}
                        />)
                    }
                    {
                        data.communicativeSkills?.Writing > 9 && (<ScoreCircle
                            value={data.communicativeSkills?.Writing || 0}
                            label="Writing"
                            color={skillColors.Writing}
                        />)
                    }
                    </Box>

                    <Typography variant="subtitle1" sx={{ mb: 2, color: "#ffffff", fontSize:"18px" }}>
                        Skills Breakdown
                    </Typography>

                    <Box sx={{ position: 'relative', mb: 4 }}>
                        {
                            data.communicativeSkills?.Listening > 9 && (<SkillProgressBar
                                label="Listening"
                                value={data.communicativeSkills?.Listening || 0}
                                color={skillColors.Listening}
                            />)
                        }
                        {
                            data.communicativeSkills?.Reading > 9 && (<SkillProgressBar
                                label="Reading"
                                value={data.communicativeSkills?.Reading || 0}
                                color={skillColors.Reading}
                            />)
                        }
                        {
                            data.communicativeSkills?.Speaking > 9 && (<SkillProgressBar
                                label="Speaking"
                                value={data.communicativeSkills?.Speaking || 0}
                                color={skillColors.Speaking}
                            />)
                        }
                        {
                            data.communicativeSkills?.Writing > 9 && (<SkillProgressBar
                                label="Writing"
                                value={data.communicativeSkills?.Writing || 0}
                                color={skillColors.Writing}
                            />)
                        }
                    </Box>
                    {
                        data.enablingSkills?.Grammar > 9 && 
                        <Box>
                            <Typography variant="subtitle1" sx={{ mb: 2, color: "#ffffff", fontSize:"18px" }}>
                                Enabling Skills
                            </Typography>

                            <Box sx={{ position: 'relative' }}>
                                <SkillProgressBar
                                    label="Grammar"
                                    value={data.enablingSkills?.Grammar || 0}
                                    color={skillColors.Grammar}
                                />
                                <SkillProgressBar
                                    label="Oral Fluency"
                                    value={data.enablingSkills?.OralFluency || 0}
                                    color={skillColors.OralFluency}
                                />
                                <SkillProgressBar
                                    label="Pronunciation"
                                    value={data.enablingSkills?.Pronunciation || 0}
                                    color={skillColors.Pronunciation}
                                />
                                <SkillProgressBar
                                    label="Spelling"
                                    value={data.enablingSkills?.Spelling || 0}
                                    color={skillColors.Spelling}
                                />
                                <SkillProgressBar
                                    label="Vocabulary"
                                    value={data.enablingSkills?.Vocabulary || 0}
                                    color={skillColors.Vocabulary}
                                />
                                <SkillProgressBar
                                    label="Written Discourse"
                                    value={data.enablingSkills?.WrittenDiscourse || 0}
                                    color={skillColors.WrittenDiscourse}
                                />
                            </Box>
                        </Box>
                    }
                    
                </Box>
            </Paper>
        </Box>
    );
};

export default Result;