import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Modal,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AudioComponent from "components/Recorder/AudioComponent";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import PteSpeakingPractice from "./PteSpeakingPractice";

import SendIcon from "@mui/icons-material/Send";
import { Send } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

const RespondToSituationMock = ({ questionData, onNext }) => {
  const lastId = localStorage.getItem("lastId");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  const SubCategory = params.get("subcategory");
  let id = location.pathname.split("/")[2];
  const childRef = React.useRef();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: "",
    pronunciation: 0,
    fluency: 0,
    overall: 0,
    vocabulary: 0,
    grammar: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  useEffect(() => {
    setQuestionDataApi(questionData);
  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setRecordedAudio(null);
        setStartRecording(false);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id && questionid === undefined) {
      // getQuestionBtID(id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = async (recording) => {
    setRecordedAudio(recording);

    const formData = new FormData();
    formData.append("questionId", id);

    formData.append("ansfile", recording.blob);
    const ans_sub = await postApi(`${API_PATH.STUDENTS.SUBMIT_ANS}`, formData);
    if (ans_sub.status === 201) {
      if (ans_sub?.data?.data?.score?.text !== "") {
        setScores({
          content: ans_sub?.data?.data?.score?.text || "",
          pronunciation: ans_sub?.data?.data?.score?.pronunciation || 0,
          fluency: ans_sub?.data?.data?.score?.fluency || 0,
          overall: ans_sub?.data?.data?.score?.overall || 0,
          vocabulary: ans_sub?.data?.data?.score?.vocabulary || 0,
          grammar: ans_sub?.data?.data?.score?.grammar || 0,
        });
      }
    }
  };

  const [timer, setTimer] = useState(3);
  const [startRecording, setStartRecording] = useState(() => {
    const savedRecording = localStorage.getItem("startRecording");
    return savedRecording ? JSON.parse(savedRecording) : false;
  });

  const handleAudioComplete = () => {
    console.log(timer, "Audio Complete");
    setStartRecording(true);
    setTimer(15);
  };

  const onNextClicked = async () => {
    console.log("Button clicked, calling child API...");
    setIsLoading(true); // Show loader
    try {
      // Triggering the child component's API function
      await childRef.current.handleSubmitAnalysis();
      onNext();
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const handleTimerPhaseChange = (phase) => {
    setIsNextButtonDisabled(phase !== "recording" && phase !== "completed");
  };

  return (
    <Box sx={{ bgcolor: "white", minHeight: "100vh", p: 2 }}>
      <Container
        maxWidth="lg"
        sx={{
          pt: "80px",
          p: 2,
          height: "95vh",
          position: "relative",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
            fontStyle: "italic",
            color: "black",
          }}
        >
          Listen to and read a description of Situation. You will have 20
          seconds to think about your answer. Then you will hear to beep. You
          will have 40 seconds to answer the question. Please answer as
          completely as you can.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingY: "35px",
          }}
        >
          {/* <RepeatParagraphAloud
            onAudioComplete={handleAudioComplete}
            audioFile={questionDataApi.audio_file}
            delay={3}
          /> */}

          <PteSpeakingPractice
            originalText={questionDataApi.audio_text}
            id={questionDataApi._id}
            audioFile={questionDataApi.audio_file}
            module={questionDataApi?.module_type}
            sub_module={questionDataApi?.module_subcategory}
            SubCategory={SubCategory}
            timer={startRecording ? timer : 3}
            startRecording={startRecording}
            ref={childRef}
            onTimerPhaseChange={handleTimerPhaseChange}
          />
        </Box>

        <Typography sx={{ color: "black !important", fontSize: "16px" }}>
          {questionDataApi.question_text}{" "}
        </Typography>
      </Container>

      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => navigate("/mocktest")}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={!isLoading && <Send />}
              onClick={onNextClicked}
              disabled={isNextButtonDisabled || isLoading}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : lastId === "true" ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default RespondToSituationMock;
