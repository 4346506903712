import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Modal,
  Button,
  Paper,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import AudioComponent from "components/Recorder/AudioComponent";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import SendIcon from "@mui/icons-material/Send";
import PteDescribeImage from "../answers/PteDescribeImage";
import { Send } from "@mui/icons-material";

const ImageDescriptionMock = ({ questionData, onNext }) => {
  const lastId = localStorage.getItem("lastId");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];
  const childRef = React.useRef();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: "",
    pronunciation: 0,
    fluency: 0,
    overall: 0,
    vocabulary: 0,
    grammar: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  useEffect(() => {
    setQuestionDataApi(questionData);
  }, [questionData]);

  // console.log(questionData, "questionData IMages comp.");

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id && questionid === undefined) {
      // getQuestionBtID(id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = async (recording) => {
    setRecordedAudio(recording);

    const formData = new FormData();
    formData.append("questionId", id);

    formData.append("ansfile", recording.blob);
    const ans_sub = await postApi(`${API_PATH.STUDENTS.SUBMIT_ANS}`, formData);
    if (ans_sub.status === 201) {
      if (ans_sub?.data?.data?.score?.text !== "") {
        setScores({
          content: ans_sub?.data?.data?.score?.text || "",
          pronunciation: ans_sub?.data?.data?.score?.pronunciation || 0,
          fluency: ans_sub?.data?.data?.score?.fluency || 0,
          overall: ans_sub?.data?.data?.score?.overall || 0,
          vocabulary: ans_sub?.data?.data?.score?.vocabulary || 0,
          grammar: ans_sub?.data?.data?.score?.grammar || 0,
        });
      }
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
    }
  };

  const handleTimerPhaseChange = (phase) => {
    setIsNextButtonDisabled(phase !== "recording" && phase !== "completed");
  };

  const onNextClicked = async () => {
    console.log("Button clicked, calling child API...");
    setIsLoading(true); // Show loader
    try {
      // Triggering the child component's API function
      await childRef.current.handleSubmitAnalysis();
      onNext();
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const stopMicrophone = async () => {
    navigate("/mocktest");
  };

  return (
    <Box sx={{ bgcolor: "white", p: 2 }}>
      <Container
        maxWidth="lg"
        sx={{
          pt: "0px",
          // p: 2,
          // minHeight: "95vh",
          position: "relative",
          width: "100% !important",
        }}
      >
        {/* Instructions Text */}
        <Typography
          sx={{
            color: "black",
            // mt: 8,
            mb: 2,
            fontSize: "17px",
            fontStyle: "italic",
          }}
        >
          Look at the image below. In 25 seconds, please speak into the
          microphone and describe in detail what the image is showing. You will
          have 40 seconds to give your response.
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper
              sx={{
                p: 1,
                color: "#fff",
                // backgroundColor: "#1A1A2E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none !important",
              }}
            >
              {questionDataApi.image ? (
                <img
                  src={`https://pte-test.b-cdn.net/${questionDataApi.image}`}
                  alt="Question"
                  style={{ width: "80%", height: "auto", textAlign: "center" }}
                />
              ) : (
                <Typography>No image available</Typography>
              )}
            </Paper>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PteDescribeImage
              keywords={questionDataApi.keyWords}
              id={questionDataApi._id}
              originalText={questionDataApi.question_text}
              module={questionDataApi?.module_type}
              sub_module={questionDataApi?.module_subcategory}
              // SubCategory={SubCategory}
              timer={40}
              ref={childRef}
              onTimerPhaseChange={handleTimerPhaseChange}
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => {
              stopMicrophone();
            }}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={!isLoading && <Send />}
              onClick={onNextClicked}
              disabled={isNextButtonDisabled || isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : lastId === "true" ? (
                "Submit"
              ) : (
                "Next"
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ImageDescriptionMock;
