import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  LinearProgress,
  styled,
  IconButton,
} from "@mui/material";
import { Container, Row, Col } from "reactstrap";
import Modal from "react-modal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { getApi } from "services/axiosInstance";
import { CloseOutlined } from "@mui/icons-material";
import AudioPlayer from "./AudioPlayerAns";
import TextToSpeechPlayer from "./QuestionPlayer";
import { toast } from "react-toastify";
import { Chip } from "@mui/material";

const Analysis = () => {
  const navigate = useNavigate();
  const { id, studentId } = useParams();

  const id1 = window.location.pathname.split("/")[2];

  const handleBack = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(true);
  const [attempts, setAttempts] = useState([]);
  const [marksAttempts, setMarksAttempts] = useState([]);
  useEffect(() => {
    const fetchAttempts = async (data) => {
      setLoading(true);
      try {
        const res = await getApi(`${API_PATH.MOCK_TEST.FULL_ATTEMPT}/${id}`);
        if (res.status === 200) {
          console.log(res.data, "res.data");
          setAttempts(res.data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        console.log(err, "Error in data ");
      } finally {
        setLoading(false);
      }
    };

    const fetchOverallMarks = async (data) => {
      setLoading(true);
      try {
        const res = await getApi(`${API_PATH.MOCK_TEST.OVERALL}/${id}/${studentId}`);
        if (res.status === 200) {
          setMarksAttempts(res.data.data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        console.log(err, "Error in data ");
      } finally {
        setLoading(false);
      }
    };
    fetchAttempts();
    fetchOverallMarks();
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbutton, setFeedbackButton] = useState(true);
  const [feedback, setfeedback] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);


  const openModal = (data) => {
    console.log(data, "data");
    console.log(JSON.stringify(data), "data");
    setModalData(data);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData([]);
    setfeedback("");
    setFeedbackButton(true);
  };

  const submitFeedback = async () => {
    setLoading(true);
    try {
      const res = await postApi(`${API_PATH.ADMIN.FEEDBACK}`, {
        studentId: attempts.studentId._id,
        fullTestId: attempts.fullTestId._id,
        questionId: modalData.questionId._id,
        feedback: feedback,
      });
      if (res.status === 200) {
        toast.success("Feedback added successfully");
        setFeedbackModal(false);
        setFeedbackButton(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (err) {
      console.log(err, "Error in data ");
    } finally {
      setLoading(false);
    }
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 4,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    "& .MuiLinearProgress-bar": {
      borderRadius: 4,
      backgroundColor: "#00E676",
    },
  }));

  const BorderLinearProgresModal = styled(LinearProgress)({
    height: 8,
    borderRadius: 4,
    backgroundColor: "#e0e0e0",
    "& .MuiLinearProgress-bar": {
      borderRadius: 4,
      backgroundColor: "#4caf50",
    },
  });
  const speakingData = [
    { label: "Read Aloud", score: 60 },
    { label: "Repeat Sentence", score: 30 },
    { label: "Describe Image", score: 52 },
    { label: "Retell Lecture", score: 10 },
    { label: "Short Answer", score: 15 },
  ];

  const writingData = [
    { label: "Essay", score: 30 },
    { label: "Summarize Text", score: 59 },
  ];

  const listeningData = [
    { label: "Summarize Text", score: 60 },
    { label: "Multiple Answers", score: 79 },
    { label: "Fill in the Blanks", score: 60 },
    { label: "Highlight Summary", score: 60 },
    { label: "Single Answer", score: 99 },
    { label: "Missing Words", score: 60 },
    { label: "Incorrect Words", score: 60 },
    { label: "Write From Dictation", score: 60 },
  ];

  const readingData = [
    { label: "R/W Fill in Blanks", score: 60 },
    { label: "Multiple Answers", score: 79 },
    { label: "Reorder Paragraph", score: 59 },
    { label: "Fill in Blanks", score: 60 },
    { label: "Single Answer", score: 99 },
  ];

  const [progress, setProgress] = useState({
    ...speakingData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
    ...writingData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
    ...listeningData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
    ...readingData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
  });



  useEffect(() => {
    const allData = [
      ...speakingData,
      ...writingData,
      ...listeningData,
      ...readingData,
    ];
    allData.forEach((item) => {
      setTimeout(() => {
        setProgress((prev) => ({
          ...prev,
          [item.label]: item.score,
        }));
      }, 300);
    });
  }, []);

  const ProgressCard = ({ category, data }) => {
    // Define max scores for different categories
    const maxScores = {
      // Speaking section
      "Read Aloud": 90,
      "Repeat Sentence": 90,
      "Describe Image": 90,
      "Retell Lecture": 90,
      "Short Answer": 90,
      "Respond to Situation": 90,
      
      // Writing section
      "Essay": 90,
      "Summarize Text": 90,
      "Write Email": 90,
      
      // Reading section
      "Multiple Answers": 90,
      "Single Answer": 90,
      "Reorder Paragraph": 90,
      "Fill in Blanks": 90,
      "R/W Fill in Blanks": 90,
      
      // Listening section
      "Write From Dictation": 90,
      "Highlight Summary": 90,
      "Missing Word": 90,
      "Incorrect Words": 90
    };

    const per = (score, label) => {
      // Get max score for this question type
      const maxScore = maxScores[label] || 90;
      
      // Calculate percentage (0-100)
      const percentage = Math.min(Math.max((score / maxScore) * 100, 0), 100);
      
      return percentage;
    };

    return (
      <Card
        sx={{
          flex: "1 1 500px",
          boxShadow: "4px 4px #0000007a",
          minWidth: "300px",
          backgroundColor: "#2d2f3a",
        }}
      >
        <CardContent>
          <Typography variant="h5" sx={{ textAlign: "center", mb: 2, color: "#d1d5db" }}>
            {category}
          </Typography>
          {Object.entries(data).map(([label, { overallScore }]) => (
            <Box key={label} sx={{ mb: 3 }}>
              <Box
                sx={{ 
                  display: "flex", 
                  justifyContent: "space-between", 
                  mb: 1,
                  alignItems: "center"
                }}
              >
                <Typography variant="body2" sx={{ color: "#d1d5db", fontSize: "14px" }}>
                  {label}
                </Typography>
                <Typography variant="body2" sx={{ color: "#d1d5db", fontSize: "14px" }}>
                  {overallScore}/{maxScores[label] || 90}
                </Typography>
              </Box>
              <Box sx={{
                height: "8px",
                bgcolor: "rgba(255, 255, 255, 0.1)",
                borderRadius: "4px",
                position: "relative"
              }}>
                <Box sx={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  height: "100%",
                  width: `${per(overallScore, label)}%`,
                  bgcolor: "#00E676",
                  borderRadius: "4px",
                  transition: "width 0.3s ease"
                }} />
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  };

  const progressData = {
    Listening: {
      "Summarize Text": { overallScore: 9, questionCount: 1 },
      "Multiple Answers": { overallScore: 0, questionCount: 1 },
    },
    Speaking: {
      "Read Aloud": { overallScore: 45, questionCount: 1 },
      "Repeat Sentence": { overallScore: 30, questionCount: 1 },
    },
  };
  return (
    <Container className="" fluid>
      <Row
        className="border-bottom shadow py-lg-2 py-2"
        style={{
          backgroundColor: "cadetblue",
          verticalAlign: "middle !important",
        }}
      >
        <Col xl="2">
          <Button
            variant="outlined"
            style={{ marginTop: "13px", border: "none" }}
            onClick={handleBack}
          >
            <ArrowBackIosIcon /> Back
          </Button>
        </Col>

        <Col xl="8" className="planon">
          <h2 className="text-center text-white display-3">
            {/* Analysis for Attempt {attempts?.fullTestId.TestName} */}
          </h2>
        </Col>

        <Col xl="2"></Col>
      </Row>

      <Row
        className=" shadow py-lg-2 py-2"
        style={{ backgroundColor: "cadetblue" }}
      >
        <Col xl="12">
          <h1 className="text-center text-white">Categories</h1>
        </Col>
      </Row>

      <Row
        className="shadow py-lg-4 py-4"
        style={{ backgroundColor: "cadetblue" }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap", // Ensures items wrap on smaller screens
            gap: 4, // Maintains spacing between cards
            mx: "auto", // Centers the container
            justifyContent: "center",
            width: "100%", // Ensures responsiveness
            maxWidth: { xs: "90%", sm: "80%", md: "75%", lg: "1200px" }, // Adjusts width based on screen size
          }}
        >
          {marksAttempts && Object.entries(marksAttempts).map(([category, data]) => (
            <ProgressCard key={category} category={category} data={data} />
          ))}

        </Box>
      </Row>

      <Row
        className="border-top shadow py-lg-2 py-2"
        style={{ backgroundColor: "cadetblue" }}
      >
        <Col xl="12" className="planon">
          <h1 className="text-center text-white">Questions</h1>
        </Col>
      </Row>

      <Row
        className="shadow py-lg-4 py-4 d-flex justify-content-center"
        style={{ backgroundColor: "cadetblue" }}
      >
        {attempts?.report?.map((data, i) => (
          <Row
            key={i + 1}
            style={{
              backgroundColor: "#1c1e29",
              borderRadius: "8px",
              padding: "10px",
              width: "90%",
            }}
            className=" align-items-center mb-4"
          >
            <div className="col-6">
              <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                {data.questionId.question_title} - {data.module}{" "}
                {data.sub_module}
              </Typography>
            </div>

            <div className="col-6 d-flex justify-content-end">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "green",
                  color: "white !important",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  paddingX: "40px",
                }}
                onClick={() => openModal(data)}
              >
                {data.score.overall | 0}/{data.score.total | 0}
              </Button>
            </div>
          </Row>
        ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Read Aloud Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#2d2f3a",
            color: "#d1d5db",
            borderRadius: "8px",
            padding: "20px",
            width: "90vw",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.87)",
            zIndex: 10000,
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-between">
            <Typography
              variant="h5"
              sx={{ color: "#d1d5db", marginBottom: "20px" }}
            >
              {modalData.sub_module}
            </Typography>

            <IconButton>
              <CloseOutlined
                onClick={closeModal}
                sx={{
                  color: "red",
                }}
              />
            </IconButton>
          </div>

          

          {/* TextToSpeech for Listening module type */}
          {modalData?.module?.includes('Listening') && (
            <div style={{ 
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <TextToSpeechPlayer
                text={modalData?.questionId?.audio_text || ""}
              />
            </div>
          )}

          {/* New Listening Single/Multiple Answer UI */}
          {(
            modalData?.sub_module?.includes('Single Answer') || 
            modalData?.sub_module?.includes('Multiple Answers') || 
            modalData?.sub_module?.includes('Highlight Summary') || 
            modalData?.sub_module?.includes('Missing Word') ||
            modalData?.sub_module?.includes('Reorder Paragraph')
          ) && (
            <div className="listening-answer" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto",
              fontSize: "px"
            }}>
              {/* Add Reading Text Display */}
              {modalData?.module?.includes('Reading') && modalData?.questionId?.long_text_paragraph && (
                <div style={{ 
                  marginBottom: "20px",
                  padding: "15px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  border: "1px solid rgba(209, 213, 219, 0.3)"
                }}>
                  <Typography sx={{ 
                    color: "#d1d5db", 
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                    lineHeight: "1.6",
                    fontSize: "15px"
                  }}>
                    {modalData.questionId.long_text_paragraph}
                  </Typography>
                </div>
              )}

              {/* Question Text */}
              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "15px", textAlign: "left" }}>
                  {modalData?.questionId?.question_text}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                {modalData?.questionId?.choices?.map((choice, index) => {
                  const choiceLabel = String.fromCharCode(65 + index);
                  const isSelected = modalData.student_ans?.includes(String(index));
                  const isCorrect = choice.is_correct;
                  
                  // Show styling based on different conditions
                  const shouldHighlight = isSelected || (showAnswer && isCorrect);
                  const isIncorrectSelection = isSelected && !isCorrect;
                  
                  return (
                    <div 
                      key={choice._id}
                      style={{
                        padding: "12px 16px",
                        borderRadius: "8px",
                        backgroundColor: shouldHighlight
                          ? (isSelected 
                            ? (isCorrect ? "rgba(76, 175, 80, 0.1)" : (showAnswer ? "transparent" : "rgba(244, 67, 54, 0.1)"))
                            : "rgba(76, 175, 80, 0.1)") // for correct answers when showAnswer is true
                          : "transparent",
                        border: `1px solid ${shouldHighlight
                          ? (isSelected 
                            ? (isCorrect ? "#4CAF50" : (showAnswer ? "rgba(209, 213, 219, 0.3)" : "#F44336"))
                            : "#4CAF50") // for correct answers when showAnswer is true
                          : "rgba(209, 213, 219, 0.3)"}`,
                        display: "flex",
                        alignItems: "flex-start",
                        cursor: "default",
                        transition: "all 0.2s ease",
                        opacity: shouldHighlight ? 1 : 1,
                        width: "100%",
                        wordBreak: "break-word"
                      }}
                    >
                      <Typography 
                        sx={{ 
                          color: shouldHighlight
                            ? (isSelected 
                              ? (isCorrect ? "#4CAF50" : (showAnswer ? "#d1d5db" : "#F44336"))
                              : "#4CAF50") // for correct answers when showAnswer is true
                            : "#d1d5db",
                          marginRight: "12px",
                          fontWeight: "500",
                          flexShrink: 0
                        }}
                      >
                        {choiceLabel}.
                      </Typography>
                      <Typography 
                        sx={{ 
                          color: shouldHighlight
                            ? (isSelected 
                              ? (isCorrect ? "#4CAF50" : (showAnswer ? "#d1d5db" : "#F44336"))
                              : "#4CAF50") // for correct answers when showAnswer is true
                            : "#d1d5db",
                          textAlign: "left",
                          flex: 1,
                          wordBreak: "break-word",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        {choice.choice_text}
                      </Typography>
                    </div>
                  );
                })}
              </div>

              <div style={{ 
                marginTop: "20px", 
                display: "flex", 
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  <Typography sx={{ color: "#d1d5db", fontSize: "0.9rem" }}>
                    Score: 
                  </Typography>
                  <Chip
                    label={`${modalData?.score?.overall | 0}/${modalData?.score?.total | 0}`}
                    color={modalData?.score?.overall === modalData?.score?.total ? "success" : "error"}
                    variant="outlined"
                    sx={{
                      fontSize: "16px",
                      padding: "15px",
                      borderWidth: "2px"
                    }}
                  />
                </div>

                <div style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  <Typography sx={{ color: "#d1d5db", fontSize: "0.9rem" }}>
                    See Answer
                  </Typography>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="seeAnswerSwitch"
                      checked={showAnswer}
                      onChange={(e) => setShowAnswer(e.target.checked)}
                      style={{
                        width: "40px",
                        height: "20px",
                        cursor: "pointer",
                        backgroundColor: showAnswer ? "#4CAF50" : "#d1d5db"
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
            
          {/* New Write From Dictation UI */}
          {modalData?.sub_module?.includes('Write From Dictation') && (
            <div className="write-from-dictation" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "10px", textAlign: "left" }}>
                  Your Response:
                </Typography>
                <div style={{
                  padding: "15px",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  border: "1px solid rgba(209, 213, 219, 0.3)",
                }}>
                  <Typography sx={{ color: "#d1d5db", textAlign: "left", whiteSpace: "pre-wrap" }}>
                    {(() => {
                      try {
                        return JSON.parse(modalData?.student_ans[0] || '""');
                      } catch (e) {
                        return modalData?.student_ans[0] || "No response provided";
                      }
                    })()}
                  </Typography>
                </div>
              </div>

              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "10px", textAlign: "left" }}>
                  Correct Answer:
                </Typography>
                <div style={{
                  padding: "15px",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  border: "1px solid rgba(209, 213, 219, 0.3)",
                }}>
                  <Typography sx={{ color: "#d1d5db", textAlign: "left", whiteSpace: "pre-wrap" }}>
                    {modalData?.questionId?.answer || "No answer available"}
                  </Typography>
                </div>
              </div>

              {/* Score Display */}
              <div style={{ 
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "15px"
              }}>
                <Typography variant="h6" sx={{ 
                  color: "#d1d5db", 
                  marginBottom: "10px",
                  fontSize: "24px",
                  fontWeight: "500",
                  textAlign: "center"
                }}>
                  Score: {modalData?.score?.overall | 0}/{modalData?.score?.total | 0}
                </Typography>

                {/* Score Breakdown */}
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                  {Object.entries(modalData?.score || {}).map(([key, value]) => {
                    // Skip overall and total properties
                    if (key === 'overall' || key === 'total') return null;

                    // Format the label
                    const label = {
                      content: 'Content',
                      vocabulary: 'Vocabulary',
                      spelling: 'Spelling',
                      linguistic: 'Linguistic',
                      development: 'Development',
                      grammar: 'Grammar',
                      form: 'Form'
                    }[key] || key;

                    // Get max score for each category
                    const maxScore = 2; // Each category has a max score of 2

                    return (
                      <div key={key} style={{ marginBottom: "10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                          <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>
                            {label}
                          </Typography>
                          <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>
                            {value}/{maxScore}
                          </Typography>
                        </div>
                        <Box sx={{
                          height: "8px",
                          bgcolor: "rgba(255, 255, 255, 0.1)",
                          borderRadius: "4px",
                          position: "relative"
                        }}>
                          <Box sx={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            height: "100%",
                            width: `${(value / maxScore) * 100}%`,
                            bgcolor: "#00E676",
                            borderRadius: "4px",
                            transition: "width 0.3s ease"
                          }} />
                        </Box>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
            
          {/* New Short Answer UI */}
          {modalData?.sub_module?.includes('Short Answer') && (
            <div className="short-answer" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              <div style={{ display: "flex", gap: "30px" }}>
                {/* Left Section - Scores and Audio */}
                <div style={{ flex: "0 0 300px" }}>
                  {/* Score Display */}
                  <Typography variant="h6" sx={{ 
                    color: "#d1d5db", 
                    marginBottom: "20px",
                    fontSize: "24px",
                    fontWeight: "500",
                    textAlign: "center"
                  }}>
                    Score : {modalData?.score?.overall | 0}/{modalData?.score?.total | 0}
                  </Typography>
                  
                  {/* Content Score Bar */}
                  <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    marginBottom: "20px",
                    gap: "10px"
                  }}>
                    <Typography sx={{ 
                      color: "#d1d5db", 
                      minWidth: "80px",
                      fontSize: "14px"
                    }}>
                      Content ({Math.round(modalData?.score?.content/90 || 0)}/90)
                    </Typography>
                    <Box sx={{
                      flex: 1,
                      height: "6px",
                      bgcolor: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "3px",
                      position: "relative"
                    }}>
                      <Box sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        width: `${(modalData?.score?.content/90 || 0) * 100}%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px"
                      }} />
                    </Box>
                  </div>

                  <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    marginBottom: "20px",
                    gap: "10px"
                  }}>
                    <Typography sx={{ 
                      color: "#d1d5db", 
                      minWidth: "80px",
                      fontSize: "14px"
                    }}>
                      Fluency ({Math.round(modalData?.score?.fluency/90 || 0)}/90)
                    </Typography>
                    <Box sx={{
                      flex: 1,
                      height: "6px",
                      bgcolor: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "3px",
                      position: "relative"
                    }}>
                      <Box sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        width: `${(modalData?.score?.fluency/90 || 0) * 100}%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px"
                      }} />
                    </Box>
                  </div>

                  <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    marginBottom: "20px",
                    gap: "10px"
                  }}>
                    <Typography sx={{ 
                      color: "#d1d5db", 
                      minWidth: "80px",
                      fontSize: "14px"
                    }}>
                      Pronunciation ({Math.round(modalData?.score?.pronunciation/90 || 0)}/90)
                    </Typography>
                    <Box sx={{
                      flex: 1,
                      height: "6px",
                      bgcolor: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "3px",
                      position: "relative"
                    }}>
                      <Box sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        width: `${(modalData?.score?.pronunciation/90 || 0) * 100}%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px"
                      }} />
                    </Box>
                  </div>

                  {/* Audio Players */}
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <div>
                      <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Question</Typography>
                      <TextToSpeechPlayer text={modalData?.questionId?.audio_text || ""} />
                    </div>
                    {modalData?.score.text && (
                      <div>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Response</Typography>
                        <AudioPlayer src={`${process.env.REACT_APP_BASE_URL}/${modalData?.answer_audio}`} />
                      </div>
                    )}
                  </div>
                </div>

                {/* Right Section - Text Display */}
                <div style={{ 
                  flex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  padding: "20px",
                  minHeight: "300px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px"
                }}>
                  {/* User Response */}
                  <Typography sx={{ 
                    color: "#d1d5db", 
                    fontSize: "18px",
                    fontWeight: "500"
                  }}>
                    {modalData?.score?.text || "No response provided"}
                  </Typography>

                  {/* Question and Answer */}
                  <div>
                    <Typography sx={{ 
                      color: "#d1d5db", 
                      fontSize: "16px",
                      marginBottom: "8px"
                    }}>
                      {modalData?.questionId?.audio_text || "No question available"}
                    </Typography>
                    <Typography sx={{ 
                      fontSize: "16px"
                    }}>
                      {'=>'} <span style={{ color: "#4CAF50" }}>{modalData?.questionId?.answer?.split('=')[1] || "No answer available"}</span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Speaking - Repeat Sentence UI */}
          {(modalData?.module?.includes('Speaking') 
          && 
          modalData?.sub_module?.includes('Repeat Sentence') ||
          modalData?.sub_module?.includes('Read Aloud') ||
          modalData?.sub_module?.includes('Describe Image') ||
          modalData?.sub_module?.includes('Retell Lecture') ||
          modalData?.sub_module?.includes('Respond to Situation'))          
          && (
            <div className="speaking-repeat-sentence" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Question Image for Describe Image */}
              

              <div style={{ display: "flex", gap: "30px" }}>
                {/* Left Section - Scores and Audio */}
                <div style={{ flex: "0 0 300px" }}>
                  {/* Score Display */}
                  {(
                    modalData?.sub_module?.includes('Describe Image')
                    || modalData?.sub_module?.includes('Respond to Situation')
                  ) && modalData?.questionId?.image && (
                  <div style={{ 
                    marginBottom: "20px",
                    textAlign: "center"
                  }}>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/${modalData.questionId.image}`}
                        alt="Question Image"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "300px",
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                        }}
                      />
                    </div>
                  )}
                  <Typography variant="h6" sx={{ 
                    color: "#d1d5db", 
                    marginBottom: "20px",
                    fontSize: "24px",
                    fontWeight: "500"
                  }}>
                    Score : {modalData?.score?.overall | 0}/{modalData?.score?.total | 0}
                  </Typography>

                  {/* Score Bars */}
                  <div style={{ marginBottom: "30px" }}>
                    {/* Content Score Bar */}
                    <div style={{ marginBottom: "15px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>Content</Typography>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{modalData?.score?.content || 0}/90</Typography>
                      </div>
                      <Box sx={{
                        height: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        position: "relative"
                      }}>
                        <Box sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: `${((modalData?.score?.content || 0) / 90) * 100}%`,
                          bgcolor: "#00E676",
                          borderRadius: "4px"
                        }} />
                      </Box>
                    </div>

                    {/* Pronunciation Score Bar */}
                    <div style={{ marginBottom: "15px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>Pronun</Typography>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{modalData?.score?.pronunciation || 0}/90</Typography>
                      </div>
                      <Box sx={{
                        height: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        position: "relative"
                      }}>
                        <Box sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: `${((modalData?.score?.pronunciation || 0) / 90) * 100}%`,
                          bgcolor: "#00E676",
                          borderRadius: "4px"
                        }} />
                      </Box>
                    </div>

                    {/* Fluency Score Bar */}
                    <div style={{ marginBottom: "15px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>Fluency</Typography>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{modalData?.score?.fluency || 0}/90</Typography>
                      </div>
                      <Box sx={{
                        height: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        position: "relative"
                      }}>
                        <Box sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: `${((modalData?.score?.fluency || 0) / 90) * 100}%`,
                          bgcolor: "#00E676",
                          borderRadius: "4px"
                        }} />
                      </Box>
                    </div>
                  </div>

                  {/* Audio Players Section */}
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <div>
                      <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Question</Typography>
                      <TextToSpeechPlayer text={modalData?.questionId?.audio_text || ""} />
                    </div>
                    <div>
                      <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Response</Typography>
                      <AudioPlayer src={`${process.env.REACT_APP_BASE_URL}/${modalData?.answer_audio}`} />
                    </div>
                  </div>
                </div>

                {/* Right Section - Text Display */}
                <div style={{ 
                  flex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  padding: "20px",
                  minHeight: "300px",
                  display: "flex",
                  alignItems: "center"
                }}>
                  <div style={{ wordBreak: "break-word" }}>
                    {(modalData?.sub_module?.includes('Describe Image') 
                      ? modalData?.questionId?.answer 
                      : modalData?.questionId?.audio_text)?.split(' ').map((word, index, array) => {
                      const spokenWords = modalData?.score?.text?.toLowerCase().split(' ') || [];
                      const isCorrect = spokenWords.includes(word.toLowerCase());
                      
                      return (
                        <span
                          key={index}
                          style={{
                            color: isCorrect ? "#4CAF50" : "#F44336",
                            marginRight: index === array.length - 1 ? 0 : "8px",
                            fontSize: "18px",
                            lineHeight: "1.6",
                            display: "inline-block"
                          }}
                        >
                          {word}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Writing Essay UI */}
          {(modalData?.module?.includes('Writing') 
          || modalData?.module?.includes('Listening'))
          && 
          (modalData?.sub_module?.includes('Essay') ||
          modalData?.sub_module?.includes('Summarize Text') ||
          modalData?.sub_module?.includes('Write Email')
          ) && (
            <div className="writing-essay" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Question Text */}
              <div style={{
                padding: "15px",
                backgroundColor: "rgba(255, 255, 255, 0.03)",
                borderRadius: "8px",
                marginBottom: "20px"
              }}>
                <Typography sx={{ 
                  color: "#d1d5db",
                  fontSize: "16px",
                  fontWeight: "500"
                }}>
                  {modalData?.questionId?.question_text}
                </Typography>
              </div>

              <div style={{ display: "flex", gap: "30px" }}>
                {/* Left Section - Scores */}
                <div style={{ flex: "0 0 300px" }}>
                  {/* Score Display */}
                  <Typography variant="h6" sx={{ 
                    color: "#d1d5db", 
                    marginBottom: "20px",
                    fontSize: "24px",
                    fontWeight: "500"
                  }}>
                    Score: {modalData?.score?.overall | 0}/{modalData?.score?.total | 0}
                  </Typography>

                  {/* Score Bars */}
                  <div style={{ marginBottom: "30px" }}>
                    {Object.entries(modalData?.score || {}).map(([key, value]) => {
                      // Skip overall and total properties as they're not score bars
                      if (key === 'overall' || key === 'total' || key === 'text') return null;

                      // Get max score for each category
                      const maxScore = {
                        content: 2,
                        form: 1,
                        grammar: 2,
                        vocabulary: 2,
                        spelling: 2
                      }[key] || 2; // default to 2 if not specified


                      const maxScoreListening = {
                        content: 2,
                        form: 1,
                        grammar: 2,
                        vocabulary: 2,
                        spelling: 2
                      }[key] || 2; //

                      // Format the label
                      const label = {
                        content: 'Content',
                        form: 'Form',
                        grammar: 'Grammar',
                        vocabulary: 'Vocab',
                        spelling: 'Spelling',
                        linguistic: 'Linguist',
                        development: 'Develop'
                      }[key] || key;

                      return (
                        <div key={key} style={{ marginBottom: "15px" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                            <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{label}</Typography>
                            {
                              modalData?.module?.includes('Writing')&&
                              <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{value}/{maxScore}</Typography>
                            }
                            {
                              modalData?.module?.includes('Listening')&&
                              <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{value}/{maxScoreListening}</Typography>
                            }
                          </div>
                          <Box sx={{
                            height: "8px",
                            bgcolor: "rgba(255, 255, 255, 0.1)",
                            borderRadius: "4px",
                            position: "relative"
                          }}>
                            {
                              modalData?.module?.includes('Writing')&&
                              <Box sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                height: "100%",
                                width: `${(value / maxScore) * 100}%`,
                                bgcolor: "#00E676",
                                borderRadius: "4px"
                              }} />
                            }
                            {
                              modalData?.module?.includes('Listening')&&
                              <Box sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                height: "100%",
                                width: `${(value / maxScoreListening) * 100}%`,
                                bgcolor: "#00E676",
                                borderRadius: "4px"
                              }} />
                            }
                            
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Right Section - Essay Text */}
                <div style={{ 
                  flex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  padding: "20px",
                  minHeight: "300px",
                  display: "flex",
                  alignItems: "center"
                }}>
                  <div style={{ wordBreak: "break-word" }}>
                    {modalData?.questionId?.answer?.split(' ').map((word, index, array) => {
                      const spokenWords = modalData?.score?.text?.toLowerCase().split(' ') || [];
                      const isCorrect = spokenWords.includes(word.toLowerCase());
                      
                      return (
                        <span
                          key={index}
                          style={{
                            color: isCorrect ? "#4CAF50" : "#F44336",
                            marginRight: index === array.length - 1 ? 0 : "8px",
                            fontSize: "18px",
                            lineHeight: "1.6",
                            display: "inline-block"
                          }}
                        >
                          {word}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Suggestions Section */}
              {modalData?.suggestions && (
                <div style={{ 
                  marginTop: "20px",
                  padding: "15px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px"
                }}>
                  <Typography sx={{ 
                    color: "#d1d5db",
                    fontSize: "14px"
                  }}>
                    Suggestions: {modalData.suggestions}
                  </Typography>
                </div>
              )}
            </div>
          )}

          {/* RW Fill in Blanks UI */}
          {modalData?.sub_module?.includes('RW Fill in Blanks') && (
            <div style={{ marginBottom: "20px" }}>
              {/* Score Display at Top */}
              <Typography variant="h6" sx={{ 
                color: "#d1d5db", 
                marginBottom: "20px", 
                textAlign: "center",
                fontSize: "24px"
              }}>
                Score: {modalData?.score?.overall | 0}/{modalData?.score?.total | 0}
              </Typography>

              {/* Text with Blanks */}
              <div style={{
                backgroundColor: "rgba(255, 255, 255, 0.03)",
                borderRadius: "8px",
                padding: "24px",
                lineHeight: "2",
                fontSize: "16px",
                color: "#d1d5db"
              }}>
                {(() => {
                  try {
                    const text = modalData.questionId.question_text || '';
                    const blanks = modalData.questionId.rwF || [];
                    const correctAnswers = modalData.questionId.rwF_ans || [];
                    const userAnswers = JSON.parse(modalData.student_ans[0] || '[]');

                    // Split text by blanks and create array of text and blanks
                    return text.split('___').map((part, index, array) => {
                      // If it's the last part, just return it
                      if (index === array.length - 1) {
                        return <span key={`text-${index}`}>{part}</span>;
                      }

                      const userAnswer = userAnswers[index];
                      const correctAnswer = correctAnswers[index];
                      const isCorrect = userAnswer === correctAnswer;

                      return (
                        <React.Fragment key={`blank-${index}`}>
                          <span>{part}</span>
                          <span style={{
                            padding: "4px 12px",
                            borderRadius: "4px",
                            backgroundColor: showAnswer 
                              ? "rgba(76, 175, 80, 0.2)"
                              : (isCorrect ? "rgba(76, 175, 80, 0.2)" : "rgba(244, 67, 54, 0.2)"),
                            border: `1px solid ${showAnswer 
                              ? "#4CAF50"
                              : (isCorrect ? "#4CAF50" : "#F44336")}`,
                            color: showAnswer
                              ? "#4CAF50"
                              : (isCorrect ? "#4CAF50" : "#F44336"),
                            margin: "0 4px",
                            display: "inline-block"
                          }}>
                            {showAnswer ? correctAnswer : (userAnswer || '')}
                          </span>
                        </React.Fragment>
                      );
                    });
                  } catch (error) {
                    console.error('Error rendering RW Fill in Blanks:', error);
                    return <span>Error displaying content</span>;
                  }
                })()}
              </div>

              {/* See Answer Toggle */}
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                padding: "0 24px"
              }}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>
                    See Answer
                  </Typography>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="seeAnswerSwitch"
                      checked={showAnswer}
                      onChange={(e) => setShowAnswer(e.target.checked)}
                      style={{
                        width: "40px",
                        height: "20px",
                        cursor: "pointer",
                        backgroundColor: showAnswer ? "#4CAF50" : "#d1d5db"
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Fill in The Blanks UI */}
          {(modalData?.sub_module?.includes('Fill in The Blanks')
          || modalData?.sub_module?.includes('Fill in Blanks'))
          && (
            <div style={{ marginBottom: "20px" }}>
              {/* Score Display at Top */}
              <Typography variant="h6" sx={{ 
                color: "#d1d5db", 
                marginBottom: "20px", 
                textAlign: "center",
                fontSize: "24px"
              }}>
                Score: {modalData?.score?.overall | 0}/{modalData?.score?.total | 0}
              </Typography>

              {/* Text with Blanks */}
              <div style={{
                backgroundColor: "rgba(255, 255, 255, 0.03)",
                borderRadius: "8px",
                padding: "24px",
                lineHeight: "2",
                fontSize: "16px",
                color: "#d1d5db"
              }}>
                {(() => {
                  const text = modalData.questionId.question_text;
                  const blanks = modalData.questionId.FillInTheBlanksAnswers;
                  const userAnswers = (() => {
                    try {
                      return JSON.parse(modalData.answers[0]);
                    } catch (e) {
                      return {};
                    }
                  })();

                  // Split text by blanks and create array of text and blanks
                  const parts = text.split('____').map((part, index) => {
                    if (index === text.split('____').length - 1) {
                      return part;
                    }
                    
                    const isCorrect = userAnswers[`blank${index + 1}`];
                    const answer = blanks[index];

                    return (
                      <>
                        {part}
                        <span style={{
                          padding: "4px 12px",
                          borderRadius: "4px",
                          backgroundColor: showAnswer || isCorrect ? "rgba(76, 175, 80, 0.2)" : "transparent",
                          border: `1px solid ${showAnswer || isCorrect ? "#4CAF50" : "#d1d5db"}`,
                          color: showAnswer || isCorrect ? "#4CAF50" : "#d1d5db",
                          margin: "0 4px",
                          display: "inline-block"
                        }}>
                          {answer}
                        </span>
                      </>
                    );
                  });

                  return parts;
                })()}
              </div>

              {/* See Answer Toggle */}
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                padding: "0 24px"
              }}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>
                    See Answer
                  </Typography>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="seeAnswerSwitch"
                      checked={showAnswer}
                      onChange={(e) => setShowAnswer(e.target.checked)}
                      style={{
                        width: "40px",
                        height: "20px",
                        cursor: "pointer",
                        backgroundColor: showAnswer ? "#4CAF50" : "#d1d5db"
                      }}
                    />
                  </div>
                </div>


              </div>
            </div>
          )}

          {/* Incorrect Words UI */}
          {modalData?.sub_module?.includes('Incorrect Words') && (
            <div style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Score Display */}
              <Typography variant="h6" sx={{ 
                color: "#d1d5db", 
                marginBottom: "20px", 
                textAlign: "center",
                fontSize: "24px"
              }}>
                Score: {modalData?.score?.overall | 0}/{modalData?.score?.total | 0}
              </Typography>

              {/* Audio Player */}
              <div style={{ 
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <TextToSpeechPlayer text={modalData?.questionId?.audio_text || ""} />
              </div>

              {/* Text Display with Highlighted Words */}
              <div style={{
                backgroundColor: "rgba(255, 255, 255, 0.03)",
                borderRadius: "8px",
                padding: "24px",
                lineHeight: "2",
                fontSize: "16px",
                color: "#d1d5db"
              }}>
                {(() => {
                  try {
                    // Get the original text and user's response
                    const originalText = modalData?.questionId?.audio_text || "";
                    const userText = modalData?.questionId?.question_text || "";
                    const correctIndexes = modalData?.questionId?.mcq_ans?.map(Number) || [];

                    // Split both texts into words
                    const userWords = userText.split(' ');

                    // Create an array to store word comparisons
                    return userWords.map((word, index) => {
                      const originalWord = originalText.split(' ')[index] || '';
                      const isIncorrect = correctIndexes.includes(index);
                      const isDifferent = word.toLowerCase() !== originalWord.toLowerCase();

                      // Style based on word comparison
                      let style = {
                        display: 'inline-block',
                        margin: '0 4px',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        color: "#d1d5db"
                      };

                      if (isIncorrect && isDifferent) {
                        return (
                          <span key={index} style={style}>
                            <span style={{
                              color: "#F44336",
                              position: "relative"
                            }}>
                              {word}
                              <span style={{
                                position: "absolute",
                                left: 0,
                                top: "-18px",
                                fontSize: "14px",
                                color: "#4CAF50",
                                textDecoration: "line-through",
                                textDecorationColor: "#F44336"
                              }}>
                                {originalWord}
                              </span>
                            </span>
                          </span>
                        );
                      }

                      return (
                        <span key={index} style={style}>
                          {word}
                        </span>
                      );
                    });
                  } catch (error) {
                    console.error('Error rendering Incorrect Words:', error);
                    return <span>Error displaying content</span>;
                  }
                })()}
              </div>

              {/* Legend */}
              <div style={{
                marginTop: "20px",
                display: "flex",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <span style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#F44336",
                    borderRadius: "4px"
                  }}></span>
                  <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>
                    Incorrect Word
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <span style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#4CAF50",
                    borderRadius: "4px",
                    textDecoration: "line-through",
                    textDecorationColor: "#F44336"
                  }}></span>
                  <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>
                    Correct Word
                  </Typography>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-between mt-4">
            {modalData.feedback ? (
              <p>
                {modalData.feedback && "Feedback -"} {modalData.feedback}
              </p>
            ) : (
              <p>
                {feedback && "Feedback -"} {feedback}
              </p>
            )}

            {feedbutton === true && modalData.feedback === "" && (
              <button
                className="btn btn-success"
                onClick={() => setFeedbackModal(true)}
              >
                Add Feedback
              </button>
            )}
          </div>
        </div>
      </Modal>

        <Modal
          isOpen={feedbackModal}
          onRequestClose={() => {
            setFeedbackModal(false);
            setfeedback("");
            setFeedbackButton(true);
          }}
          contentLabel="Read Aloud Modal"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#2d2f3a",
              color: "#d1d5db",
              borderRadius: "8px",
              padding: "20px",
              width: "70%",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div className="d-flex justify-content-between">
              <Typography
                variant="h5"
                sx={{ color: "#d1d5db", marginBottom: "20px" }}
              >
                Add Feedback
              </Typography>

              <IconButton>
                <CloseOutlined
                  onClick={() => setFeedbackModal(false)}
                  sx={{
                    color: "red",
                  }}
                />
              </IconButton>
            </div>

            <div className="d-flex align-items-center justify-content-evenly mt-3">
              <textarea
                className="w-100 bg-transparent text-white"
                name=""
                id=""
                rows={9}
                resize="none"
                onChange={(e) => setfeedback(e.target.value)}
              />
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-success"
                onClick={() => submitFeedback()}
              >
                Submit Feedback
              </button>
            </div>
          </div>
        </Modal>
      </Row>
    </Container>
  );
};

export default Analysis;
