// TextAreaCard.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  Container,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import SendIcon from "@mui/icons-material/Send";
import { Send } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

const total = 15.0;
function WriteEmailMock({ questionData, onNext }) {
  const timeLimit = 9;
  const wordLimit = { min: 200, max: 300 };

  const [body, setBody] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit * 60);
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scoreButton, setScoreButton] = useState(false);
  const [highlightedText, setHighlightedText] = useState(false);
  const [sampleText, setSampleText] = useState("");
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  useEffect(() => {
    if (isPaused || timeRemaining <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isPaused, timeRemaining]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setBody(newText);
    const words = newText.trim().split(/\s+/).filter(Boolean).length;
    setWordCount(words);
  };
  const lastId = localStorage.getItem("lastId");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);
  const [relevantKeywords, setRelevantKeywords] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  useEffect(() => {
    setQuestionDataApi(questionData);
    setSampleText(questionData.answer);
    setRelevantKeywords(questionData.keyWords);
  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setSampleText(res.data.data.answer);
        setRelevantKeywords(res.data.data.keyWords);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      // getQuestionBtID(id);
    }
  }, []);

  const handleNext = async () => {
    setIsLoadingNext(true);
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      setIsLoadingNext(false);
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      await getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
      setIsLoadingNext(false);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const handleChangeCategory = () => {
    handleOpen();
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const [checked, setChecked] = useState(false);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  // Email Code
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  // const [wordCount, setWordCount] = useState(0);
  const [scores, setScores] = useState({
    total: total,
    overall: 0,
    content: 0,
    form: 0,
    grammar: 0,
    vocabulary: 0,
    spelling: 0,
    development: 0,
    conventions: 0,
    organisation: 0,
  });
  const [scores1, setScores1] = useState({});

  const checkRelevancy = (text) => {
    const textLower = text.toLowerCase();
    const foundKeywords = relevantKeywords.filter((keyword) =>
      textLower.includes(keyword.toLowerCase())
    );
    return {
      isRelevant: foundKeywords.length >= 2, // Assume 2 keywords are required
      keywordCount: foundKeywords.length,
      foundKeywords,
    };
  };

  // Evaluate Grammar, Spelling, and other aspects
  const evaluateText = async () => {
    setLoading(true);
    try {
      const response = await postApi(API_PATH.STUDENTS.CHECK_GRAMMAR, {
        text: body,
      });

      const grammarErrors = response.data.result.edits;
      const grammarResult = response.data.result;
      const relevancy = checkRelevancy(body);

      const errorPenalty = grammarErrors.length * 5;

      let newScores = {
        total: total,
        overall: 0,
        content: 0,
        form: 0,
        grammar: 0,
        vocabulary: 0,
        spelling: 0,
        linguistic: 0,
        development: 0,
      };

      const calculateConventions = () => {
        if (!grammarErrors) return 90;
        const conventionErrors = grammarErrors.filter(
          (edit) => edit.err_cat === "PUNCT" || edit.edit_type === "FORMAT"
        );
        return Math.max(0, 90 - conventionErrors.length * 3);
      };

      const calculateOrganization = () => {
        let score = 90;
        const paragraphs = body.split("\n\n").filter((p) => p.trim());

        if (paragraphs.length < 3) score -= 20;
        if (!body.includes("\n")) score -= 10;

        const transitions = [
          "however",
          "moreover",
          "furthermore",
          "therefore",
          "consequently",
        ];
        const hasTransitions = transitions.some((word) =>
          body.toLowerCase().includes(word)
        );
        if (!hasTransitions) score -= 10;

        // Additional penalty for organizational errors from API
        const organizationErrors = grammarErrors.filter(
          (e) => e.rule?.category === "ORGN"
        );
        score -= organizationErrors.length * 5;

        return Math.max(0, score);
      };

      if (relevancy.isRelevant) {
        const baseScore = 90;
        const errorPenalty = grammarErrors.length * 5;

        // Calculate content score out of 100
        const contentScore = Math.max(0, baseScore - errorPenalty);
        const formScore = Math.max(0, baseScore - errorPenalty / 2);
        const grammarScore = Math.max(
          0,
          baseScore - 
          grammarErrors.filter((e) => e.rule?.category === "GRMR").length * 10
        );
        const vocabularyScore = Math.max(
          0,
          (relevancy.keywordCount / relevantKeywords.length) * 100
        );
        const spellingScore = Math.max(
          0,
          baseScore - 
          grammarErrors.filter((e) => e.rule?.category === "SPELL").length *
          10
        );

        // Scale all scores to be out of 2
        const contentScoreOutOf2 = Math.round((contentScore / 100) * 3);
        const formScoreOutOf2 = Math.round((formScore / 100) * 2);
        const grammarScoreOutOf2 = Math.round((grammarScore / 100) * 2);
        const vocabularyScoreOutOf2 = Math.round((vocabularyScore / 100) * 2);
        const spellingScoreOutOf2 = Math.round((spellingScore / 100) * 2);
        const conventionsScore = Math.round(calculateConventions() / 100) * 2;
        const organisationScore = Math.round(calculateOrganization() / 100) * 2;

        newScores = {
          overall:
            parseFloat(contentScoreOutOf2) +
            parseFloat(formScoreOutOf2) +
            parseFloat(grammarScoreOutOf2) +
            parseFloat(vocabularyScoreOutOf2) +
            parseFloat(spellingScoreOutOf2) +
            conventionsScore +
            organisationScore,
          content: contentScoreOutOf2, // out of 2
          form: formScoreOutOf2, // out of 2
          grammar: grammarScoreOutOf2, // out of 2
          vocabulary: vocabularyScoreOutOf2, // out of 2
          spelling: spellingScoreOutOf2, // out of 2
          conventions: conventionsScore,
          organisation: organisationScore,
        };

      }

      console.log(newScores, "newScores email")
      setScores1(newScores)
      setScores(newScores);
      setErrors(grammarErrors);
      setWordCount(body.split(/\s+/).length);
      const score = {
        total: total,
        overall: newScores.overall,
        content: newScores.content,
        form: newScores.form,
        grammar: newScores.grammar,
        spelling: newScores.spelling,
        linguistic: newScores.linguistic,
        vocabulary: newScores.vocabulary,
        development: newScores.development,
        text: body,
      };

      const payload = {
        fullTestId: id,
        currentQuestionId: questionDataApi._id,
        module: questionDataApi.module_type[0],
        sub_module: questionDataApi.module_subcategory[0],
        student_ans: body,
        correctAnswers: sampleText,
        score: JSON.stringify(score),
      };
      setHighlightedText(true);
      setScoreButton(true); 
      try {
        const answer = await postApi(
          API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
          payload
        );
        if (answer.status === 200) {
          // Reset the timer to 10 minutes (600 seconds)
          setTimeLeft(10 * 60);
  
          setWordCount(0);
  
          JSON.parse(lastId) ? navigate("/mocktest") : onNext();
          setBody("");
        }
      } catch (error) { }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Display Errors in the email body
  const HighlightedText = ({ text, errors }) => {
    let displayText = text;
    const textParts = [];
    let lastIndex = 0;

    // Sort errors by offset to process them in order
    const sortedErrors = [...errors].sort((a, b) => a.start - b.start);

    sortedErrors.forEach((error) => {
      const { start, end, replace, err_cat } = error;
      const errorType = err_cat === "GRMR" ? "text" : "text";

      if (start > lastIndex) {
        textParts.push(
          <span key={`normal-${lastIndex}`} className="text-success">
            {text.slice(lastIndex, start)}
          </span>
        );
      }

      textParts.push(
        <span key={`error-${start}`} className={`p-1 ${errorType}`}>
          {replace || text.slice(start, end)}
        </span>
      );

      lastIndex = end;
    });

    if (lastIndex < text.length) {
      textParts.push(
        <span key={`normal-end`} className="">
          {text.slice(lastIndex)}
        </span>
      );
    }


    const [isEvaluated, setIsEvaluated] = useState(false);
    const handleEvaluateAndNext = async () => {
      if (!isEvaluated) {
        await evaluateText();
      } else {
        // Reset evaluation state
        setIsEvaluated(false);
        setHighlightedText(false);
        setScoreButton(false);

        // Move to next question
        if (currentQuestion === questionData.totalQuestions) {
          alert("Congratulations You have completed all questions!");
          return;
        }

        try {
          setCurrentQuestion(currentQuestion + 1);
          setCurrentIndex(currentIndex + 1);
          await getQuestionBtID(questionData.questions[currentIndex + 1].id);
          setSubmitedAns(null);
          setSelectedAnswer(null);
          setBody(""); // Clear the text area
        } catch (error) {
          console.error("Error moving to next question:", error);
        }
      }
    };

    return (
      <div
        className="bg-light p-2 rounded highLightText"
        style={{
          whiteSpace: "break-spaces",
        }}
      >
        {textParts}
      </div>
    );
  };

  // <==============count down================>
  const [timeLeft, setTimeLeft] = useState(9 * 60); // 10 minutes in seconds
  const [timesupOpen, setTimesupOpen] = useState(false); // Controls modal visibility

  useEffect(() => {
    if (timeLeft === 0) {
      setTimesupOpen(true); // Open the modal when time is up
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [timeLeft]);

  // Format time as mm:ss
  const formatTimeWriting = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 9 ? "0" : ""}${secs}`;
  };

  const handleSubmit = async () => {
    setIsLoadingNext(true);
    await evaluateText();
    const score = {
      total: total,
      overall: scores.overall,
      content: scores.content,
      form: scores.form,
      grammar: scores.grammar,
      spelling: scores.spelling,
      vocabulary: scores.vocabulary,
      development: scores.development,
      organisation: scores.organisation,
      conventions: scores.conventions,
      text: body,
    };

    console.log(scores, "scores");
    console.log(score, "scores2");
    console.log(scores1, "scores1");

    // const payload = {
    //   fullTestId: id,
    //   currentQuestionId: questionDataApi._id,
    //   module: questionDataApi.module_type[0],
    //   sub_module: questionDataApi.module_subcategory[0],
    //   student_ans: body,
    //   score: JSON.stringify(scores),
    // };

    // try {
    //   const answer = await postApi(
    //     API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
    //     payload
    //   );
    //   if (answer.status === 200) {
    //     // Reset the timer to 10 minutes (600 seconds)
    //     setTimeLeft(10 * 60);

    //     setWordCount(0);

    //     JSON.parse(lastId) ? navigate("/mocktest") : onNext();
    //     setBody("");
    //   }
    // } catch (error) { }
    setIsLoadingNext(false);
  };

  return (
    <Box sx={{ bgcolor: "white" }}>
      {/* Header */}
      {/* <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            Part 2 : Reading
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: "16px" }}
          >
            Timing : 29 - 30 minutes
          </Typography>
          <Typography sx={{ mt: 2, fontSize: "16px" }}>
            Remember : Wear Headphones
          </Typography>
          <Button
            sx={{
              background: "#00ca71",
              color: "#fff",
              border: "1px solid #00ca71",
              borderRadius: "100px",
              width: "100%",
              py: ".5rem",
              my: ".5rem",
            }}
            onClick={onNext}
          >
            Start
          </Button>
        </Box>
      </Modal> */}

      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Typography
          sx={{
            color: "black",
            fontStyle: "italic",
            mb: 2,
            fontSize: "15px",
          }}
        >
          Read a description ofa situation. Then write an E-Mail about the situation. You will have nine minutes. You should aim to write at least 100 words. Write using
          complete sentences.
        </Typography>

        <Box
          sx={{
            bgcolor: "rgba(255,255,255,0.1)",
            // p: 2,
            borderRadius: 1,
            // mb: 3,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "black",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              fontSize: "15px",
              whiteSpace: "break-spaces",
              fontWeight: "400",
              borderRadius: "8px",
            }}
          >
            {questionDataApi.question_text}
          </Typography>
        </Box>

        {/* Answer Box */}
        <Box
          sx={{
            // bgcolor: "#fff",
            borderRadius: 1,
            py: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // mb: 2,
            }}
          >
            <Typography>Answer</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography sx={{ marginRight: "25px" }}>
                Words: {wordCount}
              </Typography>

              <Typography variant="h6">
                Time Remaining: {formatTimeWriting(timeLeft)}
              </Typography>

              {/* Modal for Time Over Message */}
              <Modal open={timesupOpen} onClose={() => setTimesupOpen(false)}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    p: 4,
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6" color="error">
                    Time is Up!
                  </Typography>
                  <Typography>Please move to the next question.</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setTimesupOpen(false)}
                    sx={{ mt: 2 }}
                  >
                    OK
                  </Button>
                </Box>
              </Modal>
            </Box>
          </Box>

          {!highlightedText && (
            <TextField
              fullWidth
              multiline
              rows={10}
              value={body}
              placeholder="Type the answer here"
              onChange={(e) => handleTextChange(e)}
              disabled={timeRemaining === 0}
              spellCheck={false} // Disables spellchecking
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#E8E9F3 !important",
                  width: "100% !important",
                  "&:hover": {
                    backgroundColor: "#E8E9F3 !important",
                  },
                  "& fieldset": {
                    borderColor: "#E0E0E0",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  width: "100% !important",
                  backgroundColor: "#E8E9F3 !important",
                },
              }}
            />
          )}

          {highlightedText && <HighlightedText text={body} errors={errors} />}

        </Box>
      </Container>

      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => navigate("/mocktest")}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={!isLoadingNext && <Send />}
              onClick={handleSubmit}
              disabled={isLoadingNext}
            >
              {isLoadingNext ? <CircularProgress size={24} sx={{ color: "white" }} /> : lastId === "true" ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
export default WriteEmailMock;