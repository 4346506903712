// TextAreaCard.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  Container,
  Modal,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";


const total = 15.0
function WriteEmail({ questionData }) {
  const timeLimit = 9;
  const wordLimit = { min: 200, max: 300 };

  const [body, setBody] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit * 60);
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scoreButton, setScoreButton] = useState(false);
  const [highlightedText, setHighlightedText] = useState(false);
  const [sampleText, setSampleText] = useState("");

  // const relevantKeywords = [
  //   "hometown ",
  //   "Furthermore",
  //   "Friday",
  //   "restaurant",
  //   "fantastic",
  //   "neighbourhoods",
  // ];

  useEffect(() => {
    if (isPaused || timeRemaining <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isPaused, timeRemaining]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setBody(newText);
    const words = newText.trim().split(/\s+/).filter(Boolean).length;
    setWordCount(words);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);
  const [relevantKeywords, setRelevantKeywords] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setSampleText(res.data.data.answer);
        setRelevantKeywords(res.data.data.keyWords);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }
  }, []);

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
      // Reset timer to initial value
      setTimeRemaining(timeLimit * 60);
      // Ensure timer is running when moving to next question
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
      // Reset timer to initial value
      setTimeRemaining(timeLimit * 60);
      // Ensure timer is running when moving to next question
    }
  };

  const handleSubmit = async () => {
    const correctChoice = questionDataApi.choices.findIndex(
      (choice) => choice.is_correct
    );
    const choiceLabels = ["A", "B", "C", "D"];
    // Find the correct answer and its corresponding label
    const correctChoice1 = questionDataApi.choices.find(
      (choice) => choice.is_correct
    );
    const correctAnswerLabel =
      choiceLabels[questionDataApi.choices.indexOf(correctChoice1)];
    const selectedAnswerLabel = choiceLabels[selectedAnswer];

    if (selectedAnswer === correctChoice) {
      setSubmitedAns(true);
    } else {
      setSubmitedAns(false);
    }
    const payload = {
      questionId: id,
      answers: selectedAnswerLabel,
      correctAnswers: correctAnswerLabel,
    };
    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status === 200) {
      }
    } catch (error) { }
  };

  const [checked, setChecked] = useState(false);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  const [errors, setErrors] = useState([]);
  // const [wordCount, setWordCount] = useState(0);
  const [scores, setScores] = useState({
    content: 0,
    form: 0,
    grammar: 0,
    vocabulary: 0,
    spelling: 0,
    development: 0,
    conventions: 0,
    organisation: 0,
  });

  const checkRelevancy = (text) => {
    const textLower = text.toLowerCase();
    const foundKeywords = relevantKeywords.filter((keyword) =>
      textLower.includes(keyword.toLowerCase())
    );
    return {
      isRelevant: foundKeywords.length >= 2, // Assume 2 keywords are required
      keywordCount: foundKeywords.length,
      foundKeywords,
    };
  };

  // Evaluate Grammar, Spelling, and other aspects
  const evaluateText = async () => {
    setLoading(true);
    try {
      const response = await postApi(API_PATH.STUDENTS.CHECK_GRAMMAR, {
        text: body,
      });

      const grammarErrors = response.data.result.edits;
      const grammarResult = response.data.result;
      const relevancy = checkRelevancy(body);

      const errorPenalty = grammarErrors.length * 5;

      let newScores = {
        overall: 0,
        content: 0,
        form: 0,
        grammar: 0,
        vocabulary: 0,
        spelling: 0,
        linguistic: 0,
        development: 0,
      };

      const calculateConventions = () => {
        if (!grammarErrors) return 90;
        const conventionErrors = grammarErrors.filter(
          (edit) => edit.err_cat === "PUNCT" || edit.edit_type === "FORMAT"
        );
        return Math.max(0, 90 - conventionErrors.length * 3);
      };

      const calculateOrganization = () => {
        let score = 90;
        const paragraphs = body.split("\n\n").filter((p) => p.trim());

        if (paragraphs.length < 3) score -= 20;
        if (!body.includes("\n")) score -= 10;

        const transitions = [
          "however",
          "moreover",
          "furthermore",
          "therefore",
          "consequently",
        ];
        const hasTransitions = transitions.some((word) =>
          body.toLowerCase().includes(word)
        );
        if (!hasTransitions) score -= 10;

        // Additional penalty for organizational errors from API
        const organizationErrors = grammarErrors.filter(
          (e) => e.rule?.category === "ORGN"
        );
        score -= organizationErrors.length * 5;

        return Math.max(0, score);
      };

      if (relevancy.isRelevant) {
        const baseScore = 90;
        const errorPenalty = grammarErrors.length * 5;

        // Calculate content score out of 100
        const contentScore = Math.max(0, baseScore - errorPenalty);
        const formScore = Math.max(0, baseScore - errorPenalty / 2);
        const grammarScore = Math.max(
          0,
          baseScore -
            grammarErrors.filter((e) => e.rule?.category === "GRMR").length * 10
        );
        const vocabularyScore = Math.max(
          0,
          (relevancy.keywordCount / relevantKeywords.length) * 100
        );
        const spellingScore = Math.max(
          0,
          baseScore -
            grammarErrors.filter((e) => e.rule?.category === "SPELL").length *
              10
        );

        // Scale all scores to be out of 2
        const contentScoreOutOf2 = Math.round((contentScore / 100) * 3);
        const formScoreOutOf2 = Math.round((formScore / 100) * 2);
        const grammarScoreOutOf2 = Math.round((grammarScore / 100) * 2);
        const vocabularyScoreOutOf2 = Math.round((vocabularyScore / 100) * 2);
        const spellingScoreOutOf2 = Math.round((spellingScore / 100) * 2);
        const conventionsScore = Math.round(calculateConventions() / 100) * 2;
        const organisationScore = Math.round(calculateOrganization() / 100) * 2;

        newScores = {
          overall:
            parseFloat(contentScoreOutOf2) +
            parseFloat(formScoreOutOf2) +
            parseFloat(grammarScoreOutOf2) +
            parseFloat(vocabularyScoreOutOf2) +
            parseFloat(spellingScoreOutOf2) +
            conventionsScore +
            organisationScore
          ,
          content: contentScoreOutOf2, // out of 2
          form: formScoreOutOf2, // out of 2
          grammar: grammarScoreOutOf2, // out of 2
          vocabulary: vocabularyScoreOutOf2, // out of 2
          spelling: spellingScoreOutOf2, // out of 2 
          conventions: conventionsScore,
          organisation: organisationScore,
        };
      }
      // let newScores = {
      //   content: Math.max(0, 90 - errorPenalty),
      //   form: Math.max(0, 90 - errorPenalty / 2),
      //   grammar: Math.max(
      //     0,
      //     90 -
      //       grammarErrors.filter((e) => e.rule?.category === "GRMR").length * 10
      //   ),
      //   vocabulary: Math.max(
      //     0,
      //     (relevancy.keywordCount / relevantKeywords.length) * 100
      //   ),
      //   spelling: Math.max(
      //     0,
      //     90 -
      //       grammarErrors.filter((e) => e.rule?.category === "SPELL").length *
      //         10
      //   ),
      //   development: (developmentScore / 100) * 2,
      //   conventions: calculateConventions(),
      //   organisation: calculateOrganization(),
      // };

      setScores(newScores);
      setErrors(grammarErrors);
      setWordCount(body.split(/\s+/).length);
      const score = {
        total: total,
        overall: newScores.overall,
        content: newScores.content,
        form: newScores.form,
        grammar: newScores.grammar,
        spelling: newScores.spelling,
        linguistic: newScores.linguistic,
        vocabulary: newScores.vocabulary,
        development: newScores.development,
        text: body,
      };
      const payload = {
        questionId: id,
        stringAns: body,
        correctAnswers: sampleText,
        score: JSON.stringify(score),
      };
      setHighlightedText(true);
      setScoreButton(true);
      try {
        const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
        if (answer.status) {
          setScoreButton(true);
          // setOpenAnalysisModal(true);
        }
      } catch (error) { }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Display Errors in the email body
  const HighlightedText = ({ text, errors }) => {
    let displayText = text;
    const textParts = [];
    let lastIndex = 0;

    // Sort errors by offset to process them in order
    const sortedErrors = [...errors].sort((a, b) => a.start - b.start);

    sortedErrors.forEach((error) => {
      const { start, end, replace, err_cat } = error;
      const errorType = err_cat === "GRMR" ? "text-warning" : "text-danger";

      if (start > lastIndex) {
        textParts.push(
          <span key={`normal-${lastIndex}`} className="text-success">
            {text.slice(lastIndex, start)}
          </span>
        );
      }

      textParts.push(
        <span key={`error-${start}`} className={`p-1 ${errorType}`}>
          {replace || text.slice(start, end)}
        </span>
      );

      lastIndex = end;
    });

    if (lastIndex < text.length) {
      textParts.push(
        <span key={`normal-end`} className="text-success">
          {text.slice(lastIndex)}
        </span>
      );
    }

    return (
      <div
        className="bg-light p-2 rounded highLightText"
        style={{
          whiteSpace: "break-spaces",
        }}
      >
        {textParts}
      </div>
    );
  };

  if (loading) {
    return (
      <Box sx={{ bgcolor: "#1a1a2e", minHeight: "100vh", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: "#00BFA6" }} />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: "#1A1F2E", minHeight: "100vh" }}>
      {/* Header */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
              Write Email
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {currentQuestion}/{questionData.totalQuestions}
              </Typography>

              <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
                <i className="fas fa-chevron-left" />
              </IconButton>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenSearchModal(true)}
              >
                <i className="fas fa-search" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <i className="fas fa-rotate" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
                <i className="fas fa-chevron-right" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 9, pb: "80px" }}>
        {/* Instructions */}
        <Typography
          sx={{
            color: "#fff",
            fontStyle: "italic",
            mb: 2,
            fontSize: "17px",
          }}
        >
          Read a description of a situation. Then write an E-Mail about the
          situation. You will have ten minutes. You should aim to write at least
          100 words. Write using complete sentences.
        </Typography>

        {/* Question */}
        <Box
          sx={{
            bgcolor: "rgba(255,255,255,0.1)",
            p: 2,
            borderRadius: 1,
            mb: 3,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              fontSize: "15px",
              whiteSpace: "break-spaces",
              fontSize: "16px",
              fontWeight: "400",
              borderRadius: "8px",
            }}
          >
            {questionDataApi.question_text}
          </Typography>
        </Box>

        {/* <Typography
          sx={{
            whiteSpace: "break-spaces",
            fontSize: "16px",
            fontWeight: "400",
            marginTop: "16px",
            backgroundColor: "#f9f9f9",
            padding: "12px",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
          }}
        >
          {questionDataApi.answer}
        </Typography> */}

        {/* Answer Box */}
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: 1,
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Answer</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>words: {wordCount}</Typography>
            </Box>
          </Box>

          {!highlightedText && (
            <TextField
              fullWidth
              multiline
              rows={10}
              value={body}
              placeholder="Type the answer here"
              onChange={(e) => handleTextChange(e)}
              disabled={timeRemaining === 0}
              spellCheck={false} // Disables spellchecking
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#E8E9F3 !important",
                  width: "100% !important",
                  "&:hover": {
                    backgroundColor: "#E8E9F3 !important",
                  },
                  "& fieldset": {
                    borderColor: "#E0E0E0",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  width: "100% !important",
                  backgroundColor: "#E8E9F3 !important",
                },
              }}
            />
          )}

          {highlightedText && <HighlightedText text={body} errors={errors} />}

          <Box
            display="flex"
            alignItems="center"
            sx={{
              padding: "10px 20px",
              borderRadius: "8px",
            }}
          >
            <Switch
              checked={checked}
              onChange={(e) => handleToggle(e)}
              color="success" // Green color when toggled
            />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                marginLeft: "8px",
              }}
            >
              Show Answer
            </Typography>
          </Box>
          {checked && (
            <Typography
              sx={{
                whiteSpace: "break-spaces",
                fontSize: "16px",
                fontWeight: "400",
                marginTop: "16px",
                backgroundColor: "#f9f9f9",
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
              }}
            >
              {questionDataApi.answer}
            </Typography>
          )}
        </Box>

        {/* Timer and Submit Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            px: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography sx={{ color: "#fff" }}>Time remaining</Typography>
            <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
              {formatTime(timeRemaining)}
            </Typography>
          </Box>
          {/* <Button
            onClick={handleSubmit}
            sx={{
              mt: 2,
              bgcolor: "#00BFA6",
              color: "#fff",
              borderRadius: "8px",
              p: 2,
              "&:hover": { bgcolor: "#009e8b" },
            }}
          >
            Submit Answer
          </Button> */}
          {isSubmitting && !scoreButton && (
            <Button
              onClick={() => evaluateText()}
              sx={{
                mt: 2,
                bgcolor: "#00BFA6",
                color: "#fff !important",
                borderRadius: "8px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b", color: "#fff" },
              }}
            >
              Analyze Answer
            </Button>
          )}

          {!isSubmitting && !scoreButton && (
            <Button
              onClick={() => {
                setIsSubmitting(true);
                pauseTimer();
              }}
              sx={{
                mt: 2,
                bgcolor: "#00BFA6",
                color: "#fff !important",
                borderRadius: "8px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b", color: "#fff" },
              }}
            >
              Submit Answer
            </Button>
          )}

          {isSubmitting && scoreButton && (
            <Button
              onClick={() => {
                setOpenAnalysisModal(true);
              }}
              sx={{
                mt: 2,
                bgcolor: "#00BFA6",
                color: "#fff !important",
                borderRadius: "8px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b", color: "#fff" },
              }}
            >
              View Score
            </Button>
          )}
        </Box>
      </Container>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {/* {searchItems.length} Qs */}
              </Typography>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {/* {searchItems.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {item.isNew && (
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        New
                      </Box>
                    )}
                    {item.isVIP && (
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        VIP
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))} */}
          </Box>
        </Box>
      </Modal>

      {/* Analysis Modal */}
      <Modal
        open={openAnalysisModal}
        onClose={() => setOpenAnalysisModal(false)}
        aria-labelledby="analysis-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "#FFFFFF",
            borderRadius: "12px",
            p: 2,
            outline: "none",
            textAlign: "center",
          }}
        >
          {/* Green Checkmark Circle */}
          <Box
            sx={{
              width: "64px",
              height: "64px",
              bgcolor: "#00E676",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto 0px",
            }}
          >
            <i
              className="fas fa-check"
              style={{ color: "#FFFFFF", fontSize: "32px" }}
            />
          </Box>

          <Typography
            variant="h6"
            sx={{
              color: "#000000",
              fontSize: "18px",
              fontWeight: 500,
              mb: 0,
              textAlign: "center",
            }}
          >
            Score: {scores.overall}/{total}
          </Typography>

          {/* Score Categories */}
          <Box
            sx={{
              mb: 0,
              textAlign: "center",
              display: "inline-block",
              fontSize: "16px",
            }}
          >
            {[
              { label: "Content", score: scores.content, total: 3 },
              { label: "Form", score: scores.form, total: 2 },
              { label: "Grammar", score: scores.grammar, total: 2 },
              { label: "Vocabulary", score: scores.vocabulary, total: 2 },
              { label: "Spelling", score: scores.spelling, total: 2 },
              { label: "Conventions", score: scores.conventions, total: 2 },
              { label: "Organisation", score: scores.organisation, total: 2 },
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  width: "450px",
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    color: "#666666",
                    width: "20%",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {item.label}
                </Typography>

                <Box
                  sx={{
                    flex: 2,
                    height: "6px",
                    bgcolor: "#F0F0F0",
                    borderRadius: "3px",
                    position: "relative",
                    mx: 2,
                    width: "60%",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      height: "100%",
                      width: `${(item.score / item.total) * 100}%`,
                      bgcolor: "#00E676",
                      borderRadius: "3px",
                    }}
                  />
                </Box>

                <Typography
                  sx={{ color: "#666666", minWidth: "70px", width: "20%" }}
                >
                  {item.score}/{item.total}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* {studentText && (
                    <div className="mt-2 fs-6">
                      <HighlightedText className="p-2 fs-6" text={studentText} errors={errors} />
      
                      {!checkRelevancy(studentText).isRelevant && (
                        <div className="alert alert-danger mt-3 p-2 fs-6">
                          Content is not relevant to the topic. Please include more
                          relevant keywords and ideas.
                        </div>
                      )}
      
                      
                    </div>
                  )} */}
          {/* OK Button */}
          <Button
            fullWidth
            sx={{
              bgcolor: "#00E676",
              color: "#FFFFFF",
              borderRadius: "25px",
              py: 1,
              textTransform: "none",
              fontSize: "16px",
              "&:hover": {
                bgcolor: "#00C853",
              },
            }}
            onClick={() => setOpenAnalysisModal(false)}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default WriteEmail;
