import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Paper,
  MenuItem,
  Select,
  Modal,
  Button,
  LinearProgress,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MicrophoneRecorder from "components/Recorder/MicrophoneRecording";

import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import styled from "@emotion/styled";

import { Send } from "@mui/icons-material";

const SingleAnswerReadingMock = ({ questionData, onNext }) => {
  const lastId = localStorage.getItem("lastId");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let fullTestId = location.pathname.split("/")[2];
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
  });

  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [submitedAns, setSubmitedAns] = useState(null);
  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setQuestionDataApi(questionData);
  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  };

  useEffect(() => {
    if (id) {
      // getQuestionBtID(id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = (recording) => {
    setRecordedAudio(recording);

    if (recording.scores) {
      setScores({
        content: recording.scores.content,
        pronunciation: recording.scores.pronunciation,
        fluency: recording.scores.fluency,
        overall: Math.round(
          (recording.scores.content +
            recording.scores.pronunciation +
            recording.scores.fluency) /
          3
        ),
      });
    }
  };


  const handleAnswerSelect = (index) => {
    // Get the selected choice based on its index
    const selectedChoice = questionDataApi.choices[index];

    // Filter out all correct answers to count them
    const correctAnswers = questionDataApi.choices.filter(
      (choice) => choice.is_correct
    );

    // Get the number of correct answers
    const numCorrectAnswers = correctAnswers.length;

    // Check if the selected choice is correct
    const isCorrectChoice = selectedChoice.is_correct;

    // If the choice is correct and the user has already selected the maximum number of correct answers, do nothing
    if (isCorrectChoice && selectedAnswer.length >= numCorrectAnswers) {
      return; // Prevent selection if there are already numCorrectAnswers correct answers selected
    }

    setSelectedAnswer((prevSelectedAnswers) => {
      // If the answer is already selected, deselect it
      if (prevSelectedAnswers.includes(index)) {
        return prevSelectedAnswers.filter(
          (selectedIndex) => selectedIndex !== index
        );
      } else {
        // If it's not selected, select it (if it's correct or if we haven't hit the limit yet)
        if (isCorrectChoice || prevSelectedAnswers.length < numCorrectAnswers) {
          return [...prevSelectedAnswers, index];
        } else {
          return prevSelectedAnswers; // Prevent selecting if already reached the max correct selections
        }
      }
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const correctChoice = questionDataApi.choices.findIndex(
      (choice) => choice.is_correct
    );
    const choiceLabels = ["A", "B", "C", "D"];
    // Find the correct answer and its corresponding label
    const correctChoice1 = questionDataApi.choices.find(
      (choice) => choice.is_correct
    );
    const correctAnswerLabel =
      choiceLabels[questionDataApi.choices.indexOf(correctChoice1)];
    const selectedAnswerLabel = choiceLabels[selectedAnswer];

    setScores({ overall: correctAnswerLabel.length, total: choiceLabels.length })


    const newScores = {
      content: 0,
      pronunciation: 0,
      fluency: 0,
      overall: correctAnswerLabel.length,
      total: choiceLabels.length,
    }

    console.log({ newScores: newScores });

    // if (
    //   JSON.stringify(sortedCorrectAnswerLabels) ===
    //   JSON.stringify(sortedSelectedAnswerLabels)
    // ) {
    //   setSubmitedAns(true);
    // } else {
    //   setSubmitedAns(false);
    // }

    const payload = {
      fullTestId: fullTestId,
      currentQuestionId: questionDataApi._id,
      module: questionDataApi.module_type[0],
      sub_module: questionDataApi.module_subcategory[0],
      student_ans: JSON.stringify(selectedAnswer),
      correctAnswers: JSON.stringify(correctAnswerLabel),
      score: JSON.stringify(newScores)
    };

    console.log({ payload: payload });

    try {
      const answer = await postApi(
        API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
        payload
      );
      if (answer.status === 200) {
        setSelectedAnswer([])
        JSON.parse(lastId) ? navigate("/mocktest") : onNext();
      }
    } catch (error) { 
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Box sx={{ p: 2 }}>
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            {/* First Column */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  padding: 2,
                  textAlign: "center",
                }}
              >
                <Box sx={{ boxShadow: "0", marginX: "20px", color: "black" }}>
                  <Typography sx={{ color: "black", fontSize: "17px" }}>
                    {questionDataApi.long_text_paragraph}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {/* Second Column */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  // padding: 2,
                  textAlign: "center",
                  // borderRadius: 1,
                }}
              >
                <Container
                  maxWidth="lg"
                  sx={{
                    // pt: "80px",
                    p: 2,
                  }}
                >
                  {/* Added Instructions Text */}
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      // mt: "30px",
                      fontStyle: "italic",
                    }}
                  >
                    Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.
                  </Typography>

                  {/* <RepeatParagraphAloud /> */}
                  {/* <RepeatParagraphAloud audioText={JSON.stringify(questionDataApi.audio_text)} /> */}

                  {/* Instructions */}
                  <Box sx={{ marginY: "20px" }}>
                    <Typography sx={{ fontSize: "17px" }}>
                      {questionDataApi.question_text}
                    </Typography>
                  </Box>
                  {/* Recording Section */}

                  {/* Answer Options */}
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    {questionDataApi.choices?.map((choice, index) => {
                      // Check if this option is selected by the user
                      const isSelected = selectedAnswer.includes(index);
                      const isCorrect = choice.is_correct;

                      // Determine the background color based on the state
                      const backgroundColor =
                        submitedAns === null // Before submission
                          ? isSelected // If this option is selected
                            ? "#00BFA6" // Keep selected option highlighted (light blue)
                            : "rgba(175, 177, 178, 0.8)" // Default neutral color for non-selected answers
                          : "rgba(52, 58, 64, 0.8)"; // Neutral color for other unselected answers

                      return (
                        <Box
                          key={index}
                          onClick={() => {
                            submitedAns === null && handleAnswerSelect(index);
                          }} // Handle the user selection
                          sx={{
                            bgcolor: backgroundColor,
                            p: 2.5,
                            borderRadius: "8px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            transition: "all 0.2s ease",
                            "&:hover": {
                              // Disable hover effect after submission
                              bgcolor:
                                submitedAns === null
                                  ? isSelected // If option is selected, it will remain highlighted on hover
                                    ? "#00BFA6" // Keep selected option hover color same
                                    : "rgba(130, 130, 130, 0.9)" // Darken other options on hover
                                  : "rgba(129, 129, 129, 0.9)", // After submission, revert to the neutral color
                            },
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            {choice.choice_text}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Container sx={{ p: "0 !important" }}>
          <Box
            sx={{
              // p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              verticalAlign: "middle",
            }}
          >
            <Button
              variant="outlined"
              sx={{ color: "black !important" }}
              onClick={() => navigate("/mocktest")}
            >
              Save & Exit
            </Button>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Button
                variant="contained"
                sx={{ color: "white !important", minWidth: "100px" }}
                endIcon={!isSubmitting && <Send />}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : (lastId === "true" ? "Submit" : "Next")}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default SingleAnswerReadingMock;