import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi, postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import AudioPlayer from "layouts/listening/AudioPlayer";
import { Send } from "@mui/icons-material";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";

const MultipleAnswerListeningMock1 = ({ questionData, onNext }) => {
  const lastId = localStorage.getItem("lastId");
  const navigate = useNavigate();
  const location = useLocation();
  let fullTestId = location.pathname.split("/")[2];
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
    total: 0
  });
  const [questionDataApi, setQuestionDataApi] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [isAudioComplete, setIsAudioComplete] = useState(false); // Track audio completion
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setQuestionDataApi(questionData);
    setSelectedAnswer([]); // Reset MCQ selection when a new question appears
  }, [questionData]);

  const handleAudioComplete = () => {
    setIsAudioComplete(true);
  };

  const handleAnswerSelect = (index) => {
    setSelectedAnswer((prevSelectedAnswers) => {
      return prevSelectedAnswers.includes(index)
        ? prevSelectedAnswers.filter((i) => i !== index) // Deselect if already selected
        : [...prevSelectedAnswers, index]; // Otherwise, select it
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const choiceLabels = ["A", "B", "C", "D"];
      const selectedAnswerLabels = selectedAnswer.map(
        (index) => choiceLabels[index]
      );

      const choices = questionDataApi.choices;

      const correctChoices = choices.filter(
        (choice) => choice.is_correct
      );

      const correctAnswerLabels = correctChoices.map(
        (correctChoice) =>
          choiceLabels[questionDataApi.choices.indexOf(correctChoice)]
      );

      const correctSelectedChoices = selectedAnswer
        .filter(index => choices[index].is_correct)
        .map(index => ({
          label: choiceLabels[index],
          choice_text: choices[index].choice_text
        }));

      const sortedCorrectAnswerLabels = correctAnswerLabels.sort();
      const sortedSelectedAnswerLabels = selectedAnswerLabels.sort();

      console.log({ choices, correctChoices, correctAnswerLabels, correctSelectedChoices })

      const newScores = {
        total: correctAnswerLabels.length,
        overall: correctSelectedChoices.length,
        content: 0,
        pronunciation: 0,
        fluency: 0
      };
      // Update the scores state
      setScores(newScores); console.log("Total Choices Available:", choices.length);
      console.log("Total Correctly Selected:", correctSelectedChoices.length);
      console.log(scores);

      const payload = {
        fullTestId: fullTestId,
        currentQuestionId: questionDataApi._id,
        module: questionDataApi.module_type[0],
        sub_module: questionDataApi.module_subcategory[0],
        student_ans: selectedAnswerLabels,
        score: JSON.stringify(newScores)
      };

      await postApi(API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS, payload);
      onNext(); // Move to the next question after submission
    } catch (error) { 
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ bgcolor: "#fff", p: 2 }}>
      <Container maxWidth="lg" sx={{ p: 2 }}>
        {/* Instructions */}
        <Typography sx={{ color: "#000", mb: 2, fontSize: "15px", fontStyle: "italic" }}>
          Listen to the recording and answer the question by selecting all the correct responses.
        </Typography>

        {/* Question Text */}
        <Paper sx={{ p: 1, my: 2, color: "#000", boxShadow: "none" }}>
          <Typography>{questionDataApi.question_text}</Typography>
        </Paper>

        {/* Audio Player */}
        {/* <AudioPlayer key={questionData.id} audioText={JSON.stringify(questionDataApi.audio_text)} delay={3} /> */}

        <RepeatParagraphAloud
          onAudioComplete={handleAudioComplete} // Pass the callback to the component
          audioText={JSON.stringify(questionDataApi.audio_text)}
          audioFile={questionDataApi.audio_file}
          delay={3}
        />

        {/* Answer Options */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {questionDataApi.choices?.map((choice, index) => {
            const isSelected = selectedAnswer.includes(index);
            return (
              <Box
                key={index}
                onClick={() => handleAnswerSelect(index)}
                sx={{
                  bgcolor: isSelected ? "#00BFA6" : "rgba(52, 58, 64, 0.8)",
                  p: 2.5,
                  borderRadius: "8px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  transition: "all 0.2s ease",
                  "&:hover": {
                    bgcolor: isSelected ? "#00BFA6" : "rgba(52, 58, 64, 0.9)",
                  },
                }}
              >
                <Typography sx={{ fontSize: "14px", color: "#fff" }}>
                  {choice.choice_text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Container>

      <Container>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button variant="outlined" sx={{ color: "black !important" }} onClick={() => navigate("/mocktest")}>
            Save & Exit
          </Button>

          <Button
            variant="contained"
            sx={{ color: "white !important", minWidth: "100px" }}
            endIcon={!isSubmitting && <Send />}
            onClick={handleSubmit}
            disabled={!isAudioComplete || isSubmitting} // Disable the button until audio is complete or submitting
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : lastId === "true" ? "Submit" : "Next"}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default MultipleAnswerListeningMock1;
