import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  Select,
  MenuItem,
  FormControl,
  Switch,
  Container,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getApi } from "services/axiosInstance";
import FillInBlanksMock from "../../FITB/answers";
import SendIcon from "@mui/icons-material/Send";
import { Send } from "@mui/icons-material";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";

function FillInBlanksSelectMock({ questionData, onNext }) {
  const lastId = localStorage.getItem("lastId");
  const navigate = useNavigate();
  const location = useLocation();
  let fullTestId = location.pathname.split("/")[2];
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);

  const getQuestionIdFromUrl = () => {
    const pathSegments = location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  };

  useEffect(() => {
    if (questionData) {
      setCurrentQuestion(questionData);
      const initialAnswers = {};
      const blanksCount = (questionData.question_text.match(/___/g) || [])
        .length;
      for (let i = 0; i < blanksCount; i++) {
        initialAnswers[`blank${i + 1}`] = "";
      }
      setAnswers(initialAnswers);
      setLoading(false);
    }
  }, [questionData]);

  const handleNextQuestion = () => {
    if (currentIndex < questions.length - 1) {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
      setCurrentQuestion(questions[nextIndex]);
      setShowAnswer(false);

      const initialAnswers = {};
      const blanksCount = (
        questions[nextIndex].question_text.match(/___/g) || []
      ).length;
      for (let i = 0; i < blanksCount; i++) {
        initialAnswers[`blank${i + 1}`] = "";
      }
      setAnswers(initialAnswers);
      setIsSubmitted(false);
      setIsCorrect({});

      navigate(`/questions/${questions[nextIndex]._id}`, { replace: true });
    }
  };

  const handlePreviousQuestion = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setCurrentIndex(prevIndex);
      setCurrentQuestion(questions[prevIndex]);
      setShowAnswer(false);

      const initialAnswers = {};
      const blanksCount = (
        questions[prevIndex].question_text.match(/___/g) || []
      ).length;
      for (let i = 0; i < blanksCount; i++) {
        initialAnswers[`blank${i + 1}`] = "";
      }
      setAnswers(initialAnswers);
      setIsSubmitted(false);
      setIsCorrect({});

      navigate(`/questions/${questions[prevIndex]._id}`, { replace: true });
    }
  };

  const handleAnswerChange = (blank) => (event) => {
    setAnswers({
      ...answers,
      [blank]: event.target.value,
    });
    setIsSubmitted(false);
  };

  const handleSubmit = async () => {
    setLoading(true); // Show loader

    if (!currentQuestion) return;

    let score = 0;
    const correctAnswers = currentQuestion.rwF_ans;
    const userAnswers = Object.values(answers);

    const correctnessMap = {};
    userAnswers.forEach((answer, index) => {
      const isCorrect = answer === correctAnswers[index];
      correctnessMap[`blank${index + 1}`] = isCorrect;

      if (isCorrect) {
        score += 1;
      }
    });

    setIsCorrect(correctnessMap);
    const newScores = {
      overall: score,
      total: correctAnswers.length
    };
    const payload = {
      fullTestId: fullTestId,
      currentQuestionId: currentQuestion._id,
      student_ans: JSON.stringify(userAnswers),
      module: currentQuestion.module_type[0],
      sub_module: currentQuestion.module_subcategory[0],
      score: JSON.stringify(newScores)
    };

    try {
      const answer = await postApi(
        API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
        payload
      );
      if (answer.status === 200) {
        onNext();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleSearchItemClick = (questionId) => {
    const questionIndex = questions.findIndex((q) => q._id === questionId);
    if (questionIndex !== -1) {
      setCurrentIndex(questionIndex);
      setCurrentQuestion(questions[questionIndex]);
      setShowAnswer(false);

      // Reset answers for the new question
      const initialAnswers = {};
      const blanksCount = (
        questions[questionIndex].question_text.match(/___/g) || []
      ).length;
      for (let i = 0; i < blanksCount; i++) {
        initialAnswers[`blank${i + 1}`] = "";
      }
      setAnswers(initialAnswers);
      setIsSubmitted(false);
      setIsCorrect({});

      // Update URL and close modal
      navigate(`/questions/${questionId}`, { replace: true });
      setOpenSearchModal(false);
    }
  };

  const renderSelect = (blankIndex) => {
    if (!currentQuestion || !currentQuestion.rwF) return null;

    const options = currentQuestion.rwF[blankIndex] || [];
    const blankKey = `blank${blankIndex + 1}`;
    const isAnswerSubmitted = isSubmitted;
    const isAnswerCorrect = isCorrect[blankKey];

    return (
      <FormControl
        size="small"
        sx={{ mx: 1, display: "inline-block", verticalAlign: "middle" }}
      >
        <Select
          value={
            showAnswer
              ? currentQuestion.rwF_ans[blankIndex]
              : answers[blankKey] || ""
          }
          onChange={handleAnswerChange(blankKey)}
          disabled={showAnswer}
          displayEmpty
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            height: "25px !important",
            borderRadius: "4px !important",
            width: "auto !important",
            backgroundColor: isAnswerSubmitted
              ? isAnswerCorrect
                ? "rgba(76, 175, 80, 0.1)"
                : "rgba(244, 67, 54, 0.1)"
              : "transparent",
            "& .MuiSelect-select": {
              color: isAnswerSubmitted
                ? isAnswerCorrect
                  ? "#4CAF50"
                  : "#f44336"
                : "#000",
              padding: "0px 8px !important",
              border: "1px solid rgba(255, 255, 255, 0.3)",
              minWidth: "80px !important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
          }}
        >
          <MenuItem value="">
            <em style={{ color: "#000" }}>Select</em>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option} style={{ color: "#000" }}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "white",
          color: "#000",
        }}
      >
        Loading...
      </Box>
    );
  }

  const AnswerBox = ({ currentQuestion }) => {
    if (!currentQuestion?.rwF_ans) return null;

    return (
      <Box
        sx={{
          mt: 3,
          p: 3,
          bgcolor: "white",
          borderRadius: 1,
          border: "1px solid rgba(255,255,255,0.1)",
        }}
      >
        <Typography sx={{ color: "#fff", fontSize: "16px", mb: 2 }}>
          Correct Answers:
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {currentQuestion.rwF_ans.map((answer, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#00A693",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Blank {index + 1}:
              </Typography>

              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "14px",
                  bgcolor: "white",
                  px: 2,
                  py: 0.5,
                  borderRadius: "4px",
                }}
              >
                {answer}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Container>
      <Box sx={{ bgcolor: "white", color: "#fff" }}>
        {/* Main Content */}
        <Box sx={{ pt: 2, pb: "30px" }}>
          <Typography
            sx={{
              color: "black !important",
              fontStyle: "italic",
              fontSize: "15px",
              mb: 1,
            }}
          >
            Below is a text with blank. Click on each blank, a list of choices
            will appear. Select the appropriate answer choice for each blank.
          </Typography>

          {currentQuestion && (
            <>
              <Box sx={{ fontSize: "14px", color: "black !important" }}>
                <Typography
                  component="div"
                  sx={{ display: "inline", color: "black !important", fontSize: "16px", lineHeight: "27px" }}
                >
                  {currentQuestion.question_text
                    .split("___")
                    .map((part, index, array) => (
                      <React.Fragment key={index}>
                        {part}
                        {index < array.length - 1 && renderSelect(index)}
                      </React.Fragment>
                    ))}
                </Typography>
              </Box>

              {showAnswer && <AnswerBox currentQuestion={currentQuestion} />}
            </>
          )}
        </Box>
      </Box>

      <Container sx={{ p: "0 !important" }}>
        <Box
          sx={{
            // p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => navigate("/mocktest")}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={loading ? null : <Send />}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : lastId === "true" ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Container>
  );
}
export default FillInBlanksSelectMock;