import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  Container,
  Modal,
} from "@mui/material";
import { Switch } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import { CheckCircle } from "lucide-react";
import { AlignVerticalBottomOutlined, Send } from "@mui/icons-material";
import AudioPlayer from "layouts/listening/AudioPlayer";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";
import CircularProgress from "@mui/material/CircularProgress";

function DictationAnswersMock({ questionData, onNext }) {
  const lastId = localStorage.getItem("lastId");
  const timeLimit = 20;
  const wordLimit = { min: 200, max: 300 };
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit * 60);
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [relevantKeywords, setRelevantKeywords] = useState([]);
  const [studentText, setStudentText] = useState("");
  const [sampleText, setSampleText] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSampleAnswer, setShowSampleAnswer] = useState(false);
  const [scores, setScores] = useState({
    overall: 0,
    content: 0,
    form: 0,
    grammar: 0,
    vocabulary: 0,
    spelling: 0,
    linguistic: 0,
    development: 0,
  });
  const [isAudioComplete, setIsAudioComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isPaused || timeRemaining <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [isPaused, timeRemaining]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const handleTextChange = (newText) => {
    setStudentText(newText);
    const words = newText.trim().split(/\s+/).filter(Boolean).length;
    setWordCount(words);
  };

  const location = useLocation();
  let fullTestId = location.pathname.split("/")[2];
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    } else {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [scoreButton, setScoreButton] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [highlightedText, setHighlightedText] = useState(false);
  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  useEffect(() => {
    setQuestionDataApi(questionData);
  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setSampleText(res.data.data.answer);
        setRelevantKeywords(res.data.data.keyWords);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      // getQuestionBtID(id);
    }
  }, []);

  const handleAudioComplete = () => {
    setIsAudioComplete(true);
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Show loader
    setIsAudioComplete(false); // Reset audio completion state when submitting
    if (!questionDataApi || !questionDataApi.mcq_ans) return;

    const newScores = await evaluateText();

    const payload = {
      fullTestId: fullTestId,
      currentQuestionId: questionData._id,
      student_ans: JSON.stringify(studentText),
      module: questionData.module_type[0],
      sub_module: questionData.module_subcategory[0],
      score: JSON.stringify(newScores)
    };

    console.log({ scores: newScores, module: questionData.module_type[0], sub_module: questionData.module_subcategory[0], payload: payload });

    try {
      const answer = await postApi(
        API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
        payload
      );
      if (answer.status === 200) {
        setIsSubmitting(false);
        setWordCount(0);
        setStudentText("");
        setHighlightedText(false);
        //last id redirect to mocktest page
        JSON.parse(lastId) ? navigate("/mocktest") : onNext();
      }
    } catch (error) {
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const [checked, setChecked] = useState(false);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  // <================================>

  const checkRelevancy = (text) => {
    const textLower = text.toLowerCase();
    console.log(questionData);
    const wordsArray = questionData.answer.split(/\s+/);

    const foundKeywords = wordsArray.filter((keyword) =>
      textLower.includes(keyword.toLowerCase())
    );
    return {
      isRelevant: foundKeywords.length >= 2,
      keywordCount: foundKeywords.length,
      foundKeywords,
    };
  };

  const checkDevelopment = (studentText, sampleText) => {
    const studentWordCount = studentText.split(/\s+/).length;
    const sampleWordCount = sampleText.split(/\s+/).length;

    // If student text is the same or too similar to the sample text, set development score to 0
    if (studentWordCount <= sampleWordCount) {
      return 0; // Return 0 if the development is minimal
    }

    const wordCountDifference = studentWordCount / sampleWordCount;

    if (wordCountDifference >= 1.2) {
      return 90; // If student's text is at least 20% longer
    } else if (wordCountDifference >= 1) {
      return 70;
    }

    return 0; // Return 0 if the text is similar or short
  };

  const evaluateGrammarSpelling = async (text) => {
    try {
      setLoading(true);
      try {
        const response = await postApi(API_PATH.STUDENTS.CHECK_GRAMMAR, {
          text,
        });
        setLoading(false);

        const result = response.data.result;
        setErrors(result.edits);

        setWordCount(
          text.split(/\s+/).filter((word) => word.length > 0).length
        );
        setLoading(false);
        return result || [];
      } catch (error) { }
    } catch (error) {
      setLoading(false);
    }
  };

  // Component to highlight mistakes based on API response
  const HighlightedText = ({ text, errors }) => {
    if (!text) return null;

    let displayText = text;
    const textParts = [];
    let lastIndex = 0;

    // Sort errors by offset to process them in order
    const sortedErrors = [...errors].sort((a, b) => a.start - b.start);

    sortedErrors.forEach((error) => {
      const { start, end, replace, err_cat } = error;
      const errorType = err_cat === "GRMR" ? "text-warning" : "text-danger";

      // Add text before error
      if (start > lastIndex) {
        textParts.push(
          <span key={`normal-${lastIndex}`} className="text-success">
            {text.slice(lastIndex, start)}
          </span>
        );
      }

      // Add error text with modification or deletion
      textParts.push(
        <span key={`error-${start}`} className={`p-1 ${errorType}`}>
          {replace || text.slice(start, end)}{" "}
          {/* If replace exists, show the replacement */}
        </span>
      );

      lastIndex = end;
    });

    // Add remaining text after the last error
    if (lastIndex < text.length) {
      textParts.push(
        <span key={`normal-end`} className="text-success">
          {text.slice(lastIndex)}
        </span>
      );
    }

    return <div className=" p-2 rounded highLightText">{textParts}</div>;
  };

  const evaluateText = async () => {
    setLoading(true);
    const apiResponse = await evaluateGrammarSpelling(studentText);
    const grammarErrors = apiResponse.edits;
    const relevancy = checkRelevancy(studentText);
    const developmentScore = checkDevelopment(studentText, sampleText);

    let newScores = {
      overall: 0,
      content: 0,
      form: 0,
      grammar: 0,
      vocabulary: 0,
      spelling: 0,
      linguistic: 0,
      development: 0,
      total: 14
    };
    console.log({ relevancy });

    if (relevancy.isRelevant) {
      const baseScore = 90;
      const errorPenalty = grammarErrors.length * 5;

      // Calculate content score out of 100
      const contentScore = Math.max(0, baseScore - errorPenalty);
      const formScore = Math.max(0, baseScore - errorPenalty / 2);
      const grammarScore = Math.max(
        0,
        baseScore -
        grammarErrors.filter((e) => e.rule?.category === "GRMR").length * 10
      );
      const vocabularyScore = Math.ceil(
        0,
        (relevancy.keywordCount / relevantKeywords.length) * 100
      );
      const spellingScore = Math.max(
        0,
        baseScore -
        grammarErrors.filter((e) => e.rule?.category === "SPELL").length * 10
      );
      const linguisticScore = Math.max(0, baseScore - errorPenalty / 3);
      const developmentScoreOutOf100 = developmentScore; // Assuming it's already calculated out of 100

      // Scale all scores to be out of 2
      const contentScoreOutOf2 = Math.round((contentScore / 100) * 3);
      const formScoreOutOf2 = Math.round((formScore / 100) * 2);
      const grammarScoreOutOf2 = Math.round((grammarScore / 100) * 2);
      const vocabularyScoreOutOf2 = Math.round((vocabularyScore / 100) * 2);
      const spellingScoreOutOf2 = Math.round((spellingScore / 100) * 2);
      const linguisticScoreOutOf2 = Math.round((linguisticScore / 100) * 2);
      const developmentScoreOutOf2 = Math.round(
        (developmentScoreOutOf100 / 100) * 2
      );

      console.log({
        contentScoreOutOf2,
        formScoreOutOf2,
        grammarScoreOutOf2,
        vocabularyScoreOutOf2,
        spellingScoreOutOf2,
        linguisticScoreOutOf2,
        developmentScoreOutOf2,
      });

      newScores = {
        total: 14,
        overall:
          parseFloat(contentScoreOutOf2) +
          parseFloat(formScoreOutOf2) +
          parseFloat(grammarScoreOutOf2) +
          parseFloat(vocabularyScoreOutOf2) +
          parseFloat(spellingScoreOutOf2) +
          parseFloat(linguisticScoreOutOf2) +
          parseFloat(developmentScoreOutOf2),
        content: contentScoreOutOf2, // out of 2
        form: formScoreOutOf2, // out of 2
        grammar: grammarScoreOutOf2, // out of 2
        vocabulary: vocabularyScoreOutOf2, // out of 2
        spelling: spellingScoreOutOf2, // out of 2
        linguistic: linguisticScoreOutOf2, // out of 2
        development: developmentScoreOutOf2, // out of 2
      };
    }

    setScores(newScores);
    setErrors(grammarErrors);
    setWordCount(
      studentText.split(/\s+/).filter((word) => word.length > 0).length
    );

    setHighlightedText(true);

    setLoading(false);

    return newScores;
  };

  const highlightErrors = (text, errors) => {
    let highlightedText = text;

    // Sort errors in descending order of offset to avoid messing up positions when replacing text
    errors.sort((a, b) => b.offset - a.offset);

    // Loop through each error and highlight it
    errors.forEach((error) => {
      const { offset, length, rule } = error;
      const errorText = text.substring(offset, offset + length);

      // Decide on the highlight color based on error category
      const color = rule.category.name === "Grammar" ? "red" : "yellow"; // Grammar = red, Spelling = yellow

      // Replace the error text with a highlighted version
      const highlightedError = `<span style="background-color: ${color};">${errorText}</span>`;
      highlightedText =
        highlightedText.slice(0, offset) +
        highlightedError +
        highlightedText.slice(offset + length);
    });

    return highlightedText;
  };

  const ScoreCard = ({ label, score }) => (
    <div className="d-flex justify-content-between p-2 border rounded">
      <span className="font-weight-semibold">{label}</span>
      <div className="d-flex align-items-center gap-2">
        <span
          className={`${score >= 70 ? "text-success" : "text-danger"
            } font-weight-bold`}
        >
          {score.toFixed(1)}
        </span>
        {score >= 70 ? (
          <CheckCircle className="w-4 h-4 text-success" />
        ) : (
          <AlignVerticalBottomOutlined className="w-4 h-4 text-danger" />
        )}
      </div>
    </div>
  );

  return (
    <Box>
      <Container maxWidth="lg">
        {/* Instructions */}
        <Typography
          sx={{
            color: "#000",
            fontStyle: "italic",
            // mb: 2,
            mt: 2,
            fontSize: "17px",
          }}
        >
          You will hear a sentence. Type the sentence in the box below exactly
          as you hear it.
        </Typography>

        {/* Question */}
        <RepeatParagraphAloud
          onAudioComplete={handleAudioComplete}
          audioText={JSON.stringify(questionDataApi.audio_text)}
          audioFile={questionDataApi.audio_file}
          delay={3}
        />

        {/* Answer Box */}
        <Box
          sx={{
            borderRadius: 1,
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Answer</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>words: {wordCount}</Typography>
            </Box>
          </Box>

          {!highlightedText && (
            <TextField
              fullWidth
              multiline
              rows={10}
              value={studentText}
              className="text-dark text-stroke"
              onChange={(e) => handleTextChange(e.target.value)}
              placeholder="Enter your response here..."
              disabled={timeRemaining === 0 || isSubmitting}
              spellCheck={false} // Disables spellchecking
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#E8E9F3 !important",
                  width: "100% !important",
                  "&:hover": {
                    backgroundColor: "#E8E9F3 !important",
                  },
                  "& fieldset": {
                    borderColor: "#E0E0E0",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  width: "100% !important",
                  backgroundColor: "#E8E9F3 !important",
                },
                "&:disabled": {
                  color: "black !important",
                },
              }}
            />
          )}

          {/* */}
          {highlightedText && (
            <HighlightedText text={studentText} errors={errors} />
          )}
        </Box>
        <Container>
          <Box
            sx={{
              // p: 2,
              mt: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              verticalAlign: "middle",
            }}
          >
            <Button
              variant="outlined"
              sx={{ color: "black !important" }}
              onClick={() => navigate("/mocktest")}
            >
              Save & Exit
            </Button>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Button
                variant="contained"
                sx={{ color: "white !important" }}
                endIcon={!isLoading && <Send />}
                onClick={handleSubmit}
                disabled={!isAudioComplete || isLoading} // Disable button until audio is complete or loading
              >
                {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : (lastId === "true" ? "Submit" : "Next")}
              </Button>
            </Box>
          </Box>
        </Container>
      </Container>
    </Box>
  );
}

export default DictationAnswersMock;
