import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Paper,
  Select,
  Modal,
  MenuItem,
  Container,
  Switch,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { API_PATH } from "services/apipath";
import { getApi } from "services/axiosInstance";
import AudioPlayer from "layouts/listening/AudioPlayer";
import { Send } from "@mui/icons-material";
import { postApi } from "services/axiosInstance";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";
import CircularProgress from "@mui/material/CircularProgress";

function IncorrectWordsMock({ questionData, onNext }) {
  const lastId = localStorage.getItem("lastId");
  const navigate = useNavigate();
  const location = useLocation();
  let fullTestId = location.pathname.split("/")[2];
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
    total: 0
  });

  const [selectedWords, setSelectedWords] = useState([]);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);
  const [isAudioComplete, setIsAudioComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setQuestionDataApi(questionData);
  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      // getQuestionBtID(id);
    }
  }, []);

  const text = questionDataApi?.question_text
    ? questionDataApi.question_text
    : "";

  const words = text.split(" ").map((word, index) => ({
    word,
    id: `${word}-${index}`,
  }));

  const handleWordClick = (wordId) => {
    if (selectedWords.includes(wordId)) {
      setSelectedWords(selectedWords.filter((id) => id !== wordId));
    } else {
      setSelectedWords([...selectedWords, wordId]);
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Show loader
    setIsAudioComplete(false); // Reset audio completion state when submitting
    if (!questionDataApi || !questionDataApi.mcq_ans) return;

    const correctWordIds = questionDataApi.mcq_ans.map((id) =>
      parseInt(id, 10)
    );

    const selectedWordIds = selectedWords.map((wordId) => {
      const id = wordId.split("-")[1];
      return parseInt(id, 10);
    });

    const correctSelections = selectedWordIds.filter((id) =>
      correctWordIds.includes(id)
    );
    const incorrectSelections = selectedWordIds.filter(
      (id) => !correctWordIds.includes(id)
    );
    const missedWords = correctWordIds.filter(
      (id) => !selectedWordIds.includes(id)
    );

    console.log({ correctWordIds, selectedWords, selectedWordIds, correctSelections, incorrectSelections, missedWords });

    setScores({ overall: correctSelections.length, total: correctWordIds.length })

    const newScores = {
      overall: correctSelections.length, total: correctWordIds.length
    }
    const payload = {
      fullTestId: fullTestId,
      currentQuestionId: questionData._id,
      student_ans: JSON.stringify(selectedWords),
      module: questionData.module_type[0],
      sub_module: questionData.module_subcategory[0],
      score: JSON.stringify(newScores)
    }

    try {
      const answer = await postApi(
        API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
        payload
      );
      if (answer.status === 200) {
        onNext();
      }
    } catch (error) { 
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const handleAudioComplete = () => {
    setIsAudioComplete(true);
  };

  return (
    <Box sx={{ color: "#000" }}>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          px: 14,
          boxShadow: "none"
        }}
      >
        <Paper
          sx={{
            p: 2,
            // mb: 3,
            borderRadius: 2,
            color: "#000",
            boxShadow: "none !important"
          }}
        >
          <Typography sx={{ fontSize: "16px", boxShadow: "none !important" }}>
            You will hear a recording. Below is a transcription of the
            recording. Some words in the transcription differ from what the
            speaker(s) said. Please click on the words that are different.
          </Typography>
        </Paper>

        {/* <Aution Text /> */}
        {/* <AudioPlayer
          audioText={JSON.stringify(questionDataApi.audio_text)}
          delay={3}
        /> */}

        <RepeatParagraphAloud
          onAudioComplete={handleAudioComplete}
          audioText={JSON.stringify(questionDataApi.audio_text)}
          audioFile={questionDataApi.audio_file}
          delay={3}
        />

        {/* Text Content */}
        <Box sx={{ my: 2, lineHeight: 1.6, fontSize: "17px" }}>
          {words.map((item, index) => {
            const isSelected = selectedWords.includes(item.id);
            const isCorrect = submitedAns?.correctSelections?.includes(index);
            const isMissed = submitedAns?.missedWords?.includes(index);
            const isIncorrect =
              submitedAns?.incorrectSelections?.includes(index);

            console.log(
              isSelected,
              isCorrect,
              isMissed,
              isIncorrect,
              "isSelected, isCorrect, isMissed, isIncorrect"
            );

            let backgroundColor = "transparent";
            let color = "#000";

            if (submitedAns && !showAnswer) {
              // After submission
              if (isCorrect) {
                backgroundColor = "#05cb05"; // Green for correctly selected words
                color = "#fff";
              } else if (isMissed) {
                backgroundColor = "#0b810bcf"; // Blue for missed words
                color = "#fff";
              } else if (isIncorrect) {
                backgroundColor = "#c90c0c"; // Red for incorrect words
                color = "#fff";
              }
            } else if (showAnswer) {
              // Show correct answers based on indices
              const wordIndex = item.id.split("-")[1]; // Keep as string
              if (questionDataApi.mcq_ans.includes(wordIndex)) {
                backgroundColor = "#05cb05"; // Green for correct words
              }
            } else if (isSelected) {
              // Default selection
              backgroundColor = "#FFB800"; // Yellow for selected words
            }

            return (
              <span
                key={item.id}
                onClick={() => handleWordClick(item.id)}
                style={{
                  cursor: "pointer",
                  backgroundColor,
                  color,
                  borderRadius: "4px",
                  padding: "4px",
                  transition: "all 0.2s ease",
                  fontSize: "18px",
                }}
              >
                {item.word}{" "}
              </span>
            );
          })}
        </Box>

        {/* Fixed Footer with Background */}
        <Container sx={{ p: "0 !important" }}>
          <Box
            sx={{
              // p: 2,
              mt: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              verticalAlign: "middle",
            }}
          >
            <Button
              variant="outlined"
              sx={{ color: "black !important" }}
              onClick={() => navigate("/mocktest")}
            >
              Save & Exit
            </Button>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Button
                variant="contained"
                sx={{ color: "white !important" }}
                endIcon={!isLoading && <Send />}
                onClick={handleSubmit}
                disabled={!isAudioComplete || isLoading} // Disable button until audio is complete or loading
              >
                {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : (lastId === "true" ? "Submit" : "Next")}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* Search Modal */}
    </Box>
  );
}

export default IncorrectWordsMock;
