import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import levenshtein from "js-levenshtein";
import { ReactMic } from "react-mic";
import { Mic, Stop } from "@mui/icons-material";
import AudioComponent from "components/Recorder/AudioComponent";
import { API_PATH } from "services/apipath";
import { postApi, getApi } from "services/axiosInstance";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useLocation } from "react-router-dom";

const total = 90.0;

const PteDescribeImage = forwardRef(
  ({ keywords = [], id, originalText, module = [], sub_module = [], timer, onTimerPhaseChange }, ref) => {
    const location = useLocation();
    let fullTestId = location.pathname.split("/")[2];

    // State variables
    const [isListening, setIsListening] = useState(false);
    const [spokenWords, setSpokenWords] = useState([]);
    const [wordStatus, setWordStatus] = useState({});
    const [isRecordingStopped, setIsRecordingStopped] = useState(false);
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [preparationTimer, setPreparationTimer] = useState(25);
    const [recordingTimer, setRecordingTimer] = useState(40);
    const [timerPhase, setTimerPhase] = useState("preparation");
    const [timerVisible, setTimerVisible] = useState(true);
    const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
    const [scores, setScores] = useState({
      overall: 1,
      pronunciation: 0,
      fluency: 0,
      content: 0,
      totalScore: 0,
    });
    const [questionDataApi, setQuestionDataApi] = useState([]);
    const [color, setColor] = useState(false);

    // Refs
    const recognitionRef = useRef(null);
    const recordingTimeoutRef = useRef(null);

    useEffect(() => {
      setIsListening(false);
      setIsRecordingStopped(false);
      setPreparationTimer(10); //25
      setTimerPhase("preparation");
    }, [id]);

    const getQuestionBtID = async (questionId) => {
      try {
        const res = await getApi(
          `${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${questionId}`
        );
        if (res.status === 200) {
          setQuestionDataApi(res.data.data);
        }
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    };

    useEffect(() => {
      if (id) {
        getQuestionBtID(id);
      }
    }, [id]);

    const normalizeWord = (word) => {
      return word
        .toLowerCase()
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .trim();
    };

    const createBeep = () => {
      try {
        const audioContext = new AudioContext();
        const oscillator = audioContext.createOscillator();
        const gainNode = audioContext.createGain();

        oscillator.connect(gainNode);
        gainNode.connect(audioContext.destination);

        oscillator.type = "sine";
        oscillator.frequency.setValueAtTime(800, audioContext.currentTime);
        gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);

        oscillator.start(audioContext.currentTime);
        oscillator.stop(audioContext.currentTime + 0.15);

        setTimeout(() => {
          gainNode.disconnect();
          oscillator.disconnect();
        }, 200);
      } catch (error) {
        console.error("Error creating beep:", error);
      }
    };

    // Timer logic for preparation phase
    useEffect(() => {
      if (timerPhase !== "preparation" || preparationTimer <= 0) return;

      const intervalId = setInterval(() => {
        setPreparationTimer((prev) => {
          if (prev === 2) {
            createBeep();
          }
          if (prev <= 1) {
            setTimerPhase("recording");
            startListening();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [preparationTimer, timerPhase, id]);

    // Timer logic for recording phase
    useEffect(() => {
      if (timerPhase !== "recording" || !isListening) return;

      const intervalId = setInterval(() => {
        setRecordingTimer((prev) => {
          if (prev <= 1) {
            stopListening();
            setTimerPhase("completed");
            setTimerVisible(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [recordingTimer, timerPhase, isListening]);

    // Initialize Speech Recognition
    useEffect(() => {
      if ("webkitSpeechRecognition" in window) {
        recognitionRef.current = new window.webkitSpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;
        recognitionRef.current.lang = "en-US";

        recognitionRef.current.onresult = (event) => {
          const transcript = Array.from(event.results)
            .map((result) => result[0].transcript)
            .join(" ")
            .trim();
          const newSpokenWords = transcript.split(/\s+/).map(normalizeWord);
          setSpokenWords(newSpokenWords);
          analyzeKeywordMatch(newSpokenWords);
        };

        recognitionRef.current.onerror = (event) => {
          console.error("Speech recognition error:", event.error);
        };

        recognitionRef.current.onend = () => {
          if (timerPhase === "recording" && isListening) {
            recognitionRef.current.start();
          }
        };
      } else {
        console.error("Web Speech API is not supported in this browser");
      }

      return () => {
        if (recordingTimeoutRef.current) {
          clearTimeout(recordingTimeoutRef.current);
        }
      };
    }, [isListening, timerPhase]);

    const analyzeKeywordMatch = (spokenWords) => {
      if (!Array.isArray(keywords)) {
        console.warn('Keywords prop is not an array');
        return;
      }

      const normalizedKeywords = keywords.map(normalizeWord);
      const newWordStatus = {};

      normalizedKeywords.forEach((keyword, index) => {
        newWordStatus[index] = "missing";
      });

      spokenWords.forEach((spokenWord) => {
        normalizedKeywords.forEach((keyword, index) => {
          const distance = levenshtein(spokenWord, keyword);
          if (distance <= 1) {
            newWordStatus[index] = "correct";
          }
        });
      });

      setWordStatus(newWordStatus);
      calculateScores(newWordStatus, spokenWords);
    };

    const calculateScores = (wordStatus, spokenWords) => {
      if (!Array.isArray(keywords) || keywords.length === 0) {
        console.warn('Invalid or empty keywords array');
        return;
      }

      const correctWords = Object.values(wordStatus).filter(
        (status) => status === "correct"
      ).length;

      const totalKeywords = keywords.length;
      const pronunciationScore = Math.round((correctWords / totalKeywords) * 40);
      const idealWordCount = 60;
      const fluencyScore = Math.min(
        Math.round((spokenWords.length / idealWordCount) * 30),
        30
      );
      const contentScore = Math.round((correctWords / totalKeywords) * 30);
      const totalScore = pronunciationScore + fluencyScore + contentScore;
      const scaledTotalScore = Math.round((totalScore / 100) * 90);
      const overallScore = (pronunciationScore + fluencyScore + contentScore) / 3;

      setScores({
        pronunciation: pronunciationScore,
        fluency: fluencyScore,
        content: contentScore,
        overall: overallScore.toFixed(2),
        totalScore: scaledTotalScore,
      });
    };

    const startListening = () => {
      if (recognitionRef.current) {
        try {
          setSpokenWords([]);
          setWordStatus({});
          setIsRecordingStopped(false);
          setRecordedAudio(null);
          setScores({
            pronunciation: 0,
            fluency: 0,
            content: 0,
            totalScore: 0,
          });
          setIsListening(true);
          setTimerPhase("recording");
          setRecordingTimer(40);
          recognitionRef.current.start();

          recordingTimeoutRef.current = setTimeout(() => {
            stopListening();
          }, 40000);
        } catch (error) {
          setIsListening(false);
          console.error("Could not start speech recognition:", error);
        }
      }
    };

    const stopListening = (recordedBlob) => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        setTimerPhase("completed");
        setIsListening(false);
        setIsRecordingStopped(true);
        if (recordedBlob) {
          setRecordedAudio(recordedBlob);
        }
      }
    };

    const onStop = (recordedBlob) => {
      const audioURL = URL.createObjectURL(recordedBlob.blob);
      setRecordedAudio(audioURL);
    };

    useImperativeHandle(ref, () => ({
      handleSubmitAnalysis: async () => {
        if (recognitionRef.current) {
          recognitionRef.current.stop();
        }
        setTimerPhase("completed");
        setIsListening(false);
        setIsRecordingStopped(true);

        if (!recordedAudio) {
          console.warn("No recording available to analyze.");
          return;
        }

        try {
          const formData = new FormData();
          formData.append("fullTestId", fullTestId);
          formData.append("currentQuestionId", id);
          formData.append("ansfile", recordedAudio.blob);
          formData.append("module", module[0]);
          formData.append("sub_module", sub_module[0]);

          const score = {
            total: total,
            overall: scores.overall,
            content: scores.content,
            pronunciation: scores.pronunciation,
            fluency: scores.fluency,
            text: spokenWords.join(" "),
          };

          const text = spokenWords.join(" ");
          formData.append("student_ans", text);
          formData.append("score", JSON.stringify(score));

          const response = await postApi(
            API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
            formData
          );

          if (response.status === 201) {
            setOpenAnalysisModal(true);
            setColor(true);
          } else {
            console.warn("Failed to analyze the recording");
          }
        } catch (error) {
          console.error("An error occurred while analyzing the recording:", error);
        }
      },
    }));

    useEffect(() => {
      if (onTimerPhaseChange) {
        onTimerPhaseChange(timerPhase);
      }
    }, [timerPhase, onTimerPhaseChange]);

    return (
      <CardContent
        sx={{
          paddingX: "0px",
          width: "80%",
          border: "1px solid grey",
          borderRadius: "8px",
        }}
      >
        <ReactMic
          record={isListening}
          visualSetting="none"
          className="hidden d-none"
          onStop={onStop}
          mimeType="audio/webm"
        />

        {!isRecordingStopped && (
          <Box sx={{ textAlign: "center", paddingTop: "25px", my: 0 }}>
            <Typography variant="h6">Voice Recorder</Typography>

            {timerPhase !== "completed" && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", mb: 3, color: "black" }}
              >
                {timerPhase === "preparation"
                  ? `Preparation time: ${preparationTimer} sec`
                  : `Recording time: ${recordingTimer} sec`}
              </Typography>
            )}

            {timerPhase === "completed" && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "black" }}
              >
                Recording Done
              </Typography>
            )}
          </Box>
        )}

        {/* {isRecordingStopped && recordedAudio && (
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <AudioComponent audioUrl={recordedAudio} />
          </Box>
        )}

        <Modal
          open={openAnalysisModal}
          onClose={() => setOpenAnalysisModal(false)}
          aria-labelledby="analysis-modal-title"
          aria-describedby="analysis-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography id="analysis-modal-title" variant="h6" component="h2">
              Analysis Results
            </Typography>
            <Typography id="analysis-modal-description" sx={{ mt: 2 }}>
              Overall Score: {scores.overall}
              <br />
              Pronunciation: {scores.pronunciation}
              <br />
              Fluency: {scores.fluency}
              <br />
              Content: {scores.content}
              <br />
              Total Score: {scores.totalScore}
            </Typography>
            <Button
              onClick={() => setOpenAnalysisModal(false)}
              sx={{ mt: 2 }}
              variant="contained"
            >
              Close
            </Button>
          </Box>
        </Modal> */}
      </CardContent>
    );
  }
);

export default PteDescribeImage;